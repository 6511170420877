<div *ngIf="messages">
    <h3>Liste des retours client </h3>
    <table class="table table-hover">
		<thead>
			<tr>
				<th style="text-align: center;">Id</th>
				<th style="text-align: center;">Nom Entreprise</th>
				<th style="text-align: center;">Email</th>
				<th style="text-align: center;">Date de création</th>
				<th style="text-align: center;">Etat</th>
				<th style="text-align: center;"></th>
				<th style="text-align: center;"></th>
				<th style="text-align: center;"></th>
			</tr>
		</thead>
		<tbody>
			<tr *ngFor="let item of feedbacks ">
				<td scope="row">{{item.id}}</td>
				<td>
					<span>{{item?.entrepriseId.name}}</span>
				</td>
				<td>
					<span>{{item?.entrepriseId?.email}}</span>
				</td>
				<td>
					{{item?.createdAt | date :"dd-MM-yyyy à HH:mm" }}
				</td>
				<td>
					<span class="label label-info" *ngIf="item.treated == false">Non traité</span>
					<span class="label label-info" *ngIf="item.treated == true">Traité</span>
				</td>
				<td>
					<button *ngIf="item.treated == false" style="border:none" (click)="treatFeedBack(item)" type="button"
						class="btn btn-sm btn-outline-success">
						<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
							class="bi bi-check" viewBox="0 0 16 16">
							<path
								d="M10.97 4.97a.75.75 0 0 1 1.07 1.05l-3.99 4.99a.75.75 0 0 1-1.08.02L4.324 8.384a.75.75 0 1 1 1.06-1.06l2.094 2.093 3.473-4.425a.267.267 0 0 1 .02-.022z" />
						</svg>
					</button>
					<button *ngIf="item.treated == true" style="border:none" (click)="inTreatFeedback(item)" type="button"
						class="btn btn-sm btn-outline-danger">
						<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
							class="bi bi-x-lg" viewBox="0 0 16 16">
							<path
								d="M2.146 2.854a.5.5 0 1 1 .708-.708L8 7.293l5.146-5.147a.5.5 0 0 1 .708.708L8.707 8l5.147 5.146a.5.5 0 0 1-.708.708L8 8.707l-5.146 5.147a.5.5 0 0 1-.708-.708L7.293 8 2.146 2.854Z" />
						</svg>
					</button>
				</td>
				<td>
					<button (click)="detailAction(item)" style="border:none;" type="button" 	class="btn btn-sm btn-outline-info">
						<svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" fill="currentColor"
							class="bi bi-info-circle">
							<path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
							<path
								d="m8.93 6.588-2.29.287-.082.38.45.083c.294.07.352.176.288.469l-.738 3.468c-.194.897.105 1.319.808 1.319.545 0 1.178-.252 1.465-.598l.088-.416c-.2.176-.492.246-.686.246-.275 0-.375-.193-.304-.533L8.93 6.588zM9 4.5a1 1 0 1 1-2 0 1 1 0 0 1 2 0z" />
						</svg>
					</button>
				</td>
				<td>
					<button style="border:none" (click)="deleteAction(item)" type="button"
						class="btn btn-sm btn-outline-danger">
						<svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" fill="currentColor"
							class="bi bi-trash3">
							<path
								d="M6.5 1h3a.5.5 0 0 1 .5.5v1H6v-1a.5.5 0 0 1 .5-.5ZM11 2.5v-1A1.5 1.5 0 0 0 9.5 0h-3A1.5 1.5 0 0 0 5 1.5v1H2.506a.58.58 0 0 0-.01 0H1.5a.5.5 0 0 0 0 1h.538l.853 10.66A2 2 0 0 0 4.885 16h6.23a2 2 0 0 0 1.994-1.84l.853-10.66h.538a.5.5 0 0 0 0-1h-.995a.59.59 0 0 0-.01 0H11Zm1.958 1-.846 10.58a1 1 0 0 1-.997.92h-6.23a1 1 0 0 1-.997-.92L3.042 3.5h9.916Zm-7.487 1a.5.5 0 0 1 .528.47l.5 8.5a.5.5 0 0 1-.998.06L5 5.03a.5.5 0 0 1 .47-.53Zm5.058 0a.5.5 0 0 1 .47.53l-.5 8.5a.5.5 0 1 1-.998-.06l.5-8.5a.5.5 0 0 1 .528-.47ZM8 4.5a.5.5 0 0 1 .5.5v8.5a.5.5 0 0 1-1 0V5a.5.5 0 0 1 .5-.5Z" />
						</svg>
					</button>
				</td>
			</tr>
		</tbody>
	</table>
</div>




<div *ngIf="!messages">
    <h3>Liste des demandes de contact </h3>
    <table class="table table-hover">
		<thead>
			<tr>
				<th style="text-align: center;">Id</th>
				<th style="text-align: center;">Nom Entreprise</th>
				<th style="text-align: center;">Telephone</th>
				<th style="text-align: center;">Date de création</th>
				<th style="text-align: center;">Etat</th>
				<th style="text-align: center;"></th>
				<th style="text-align: center;"></th>
			</tr>
		</thead>
		<tbody>
			<tr *ngFor="let item of feedbacks ">
				<td scope="row">{{item.id}}</td>
				<td>
					<span>{{item?.entrepriseId.name}}</span>
				</td>
				<td>
					<span *ngIf="item?.tel">{{item?.tel}}</span>
					<span *ngIf="!item?.tel" class="label label-danger"> non disponible !</span>
				</td> 
				<td>
					{{item?.createdAt | date :"dd-MM-yyyy à HH:mm" }}
				</td>
				<td>
					<span class="label label-info" *ngIf="item.treated == false">Non traité</span>
					<span class="label label-info" *ngIf="item.treated == true">Traité</span>
				</td>
				<td>
					<button *ngIf="item.treated == false" style="border:none" (click)="treatFeedBack(item)" type="button"
						class="btn btn-sm btn-outline-success">
						<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
							class="bi bi-check" viewBox="0 0 16 16">
							<path
								d="M10.97 4.97a.75.75 0 0 1 1.07 1.05l-3.99 4.99a.75.75 0 0 1-1.08.02L4.324 8.384a.75.75 0 1 1 1.06-1.06l2.094 2.093 3.473-4.425a.267.267 0 0 1 .02-.022z" />
						</svg>
					</button>
					<button *ngIf="item.treated == true" style="border:none" (click)="inTreatFeedback(item)" type="button"
						class="btn btn-sm btn-outline-danger">
						<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
							class="bi bi-x-lg" viewBox="0 0 16 16">
							<path
								d="M2.146 2.854a.5.5 0 1 1 .708-.708L8 7.293l5.146-5.147a.5.5 0 0 1 .708.708L8.707 8l5.147 5.146a.5.5 0 0 1-.708.708L8 8.707l-5.146 5.147a.5.5 0 0 1-.708-.708L7.293 8 2.146 2.854Z" />
						</svg>
					</button>
				</td>
				<td>
					<button style="border:none" (click)="deleteAction(item)" type="button"
						class="btn btn-sm btn-outline-danger">
						<svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" fill="currentColor"
							class="bi bi-trash3">
							<path
								d="M6.5 1h3a.5.5 0 0 1 .5.5v1H6v-1a.5.5 0 0 1 .5-.5ZM11 2.5v-1A1.5 1.5 0 0 0 9.5 0h-3A1.5 1.5 0 0 0 5 1.5v1H2.506a.58.58 0 0 0-.01 0H1.5a.5.5 0 0 0 0 1h.538l.853 10.66A2 2 0 0 0 4.885 16h6.23a2 2 0 0 0 1.994-1.84l.853-10.66h.538a.5.5 0 0 0 0-1h-.995a.59.59 0 0 0-.01 0H11Zm1.958 1-.846 10.58a1 1 0 0 1-.997.92h-6.23a1 1 0 0 1-.997-.92L3.042 3.5h9.916Zm-7.487 1a.5.5 0 0 1 .528.47l.5 8.5a.5.5 0 0 1-.998.06L5 5.03a.5.5 0 0 1 .47-.53Zm5.058 0a.5.5 0 0 1 .47.53l-.5 8.5a.5.5 0 1 1-.998-.06l.5-8.5a.5.5 0 0 1 .528-.47ZM8 4.5a.5.5 0 0 1 .5.5v8.5a.5.5 0 0 1-1 0V5a.5.5 0 0 1 .5-.5Z" />
						</svg>
					</button>
				</td>
			</tr>
		</tbody>
	</table>
</div>

<app-alert-delete *ngIf="showDelete" (saved)="refreshList()"  (closed)="showDelete = false" [feedbackId]="feedbackId"></app-alert-delete>
<app-demand-detail (closed)="showDetail = false" *ngIf="showDetail" [feedbackObject]="feedbackObject"></app-demand-detail>
