<section>
  <div class="alert alert-success" role="alert" *ngIf="success">
    <div class="alert-items">
      <div class="alert-item static">
        <div class="alert-icon-wrapper">
          <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-check-circle"
            viewBox="0 0 16 16">
            <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
            <path
              d="M10.97 4.97a.235.235 0 0 0-.02.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-1.071-1.05z" />
          </svg>
        </div>
        <span class="alert-text">
          Utilisateur a été ajouté avec succès !
        </span>
      </div>
    </div>
  </div>
  <div class="alert alert-danger" role="alert" *ngIf="tailleInvalid || noCnx || usedMail || formatInvalid || noImage">
    <div class="alert-items">
      <div class="alert-item static">
        <div class="alert-icon-wrapper">
          <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-check-circle"
            viewBox="0 0 16 16">
            <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
            <path
              d="M10.97 4.97a.235.235 0 0 0-.02.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-1.071-1.05z" />
          </svg>
        </div>
        <span class="alert-text">
          {{erreurMsg}}
        </span>
      </div>
    </div>
  </div>
  <h3>Ajout d'utilisateur</h3>
  <form clrForm (ngSubmit)="submit(userForm.value,userForm)" #userForm="ngForm">
    <clr-input-container>
      <label>Nom *</label>
      <input clrInput type="text" ngModel name="name" required />
      <clr-control-error *clrIfError="'required'">Ce champ est obligatoire </clr-control-error>
    </clr-input-container>
    <clr-input-container>
      <label>Email *</label>
      <input clrInput type="text" class="form-control" name="email" ngModel required email />
      <clr-control-error *clrIfError="'required'">Ce champ est obligatoire </clr-control-error>
      <clr-control-error>Adresse mail non valide </clr-control-error>
    </clr-input-container>

    <clr-input-container>
      <label>Tel *</label>
      <input clrInput ngModel clrInput type="number" name="tel" required
        pattern="(([+]?[0-3]{2}?[0-9]{8})||([0-9]{8}))" />
      <clr-control-error *clrIfError="'required'">Ce champ est obligatoire </clr-control-error>
      <clr-control-error *clrIfError="'pattern'"> Numéro de téléphone est incorrect</clr-control-error>
    </clr-input-container>
    <!-- <clr-input-container>
      <label>Fax</label>
      <input clrInput ngModel clrInput type="number" name="fax"   pattern="(([+]?[0-3]{2}?[0-9]{8})||([0-9]{8}))" />
      <clr-control-error *clrIfError="'pattern'"> Numéro de téléphone est incorrect</clr-control-error>
    </clr-input-container> -->
    <clr-password-container>
      <label>Mot de passe *</label>
      <input clrPassword type="Password" placeholder="saisir votre mot de passe" ngModel name="password" required
        minlength="4" />
      <clr-control-error *clrIfError="'required'">Ce champ est obligatoire </clr-control-error>
      <clr-control-helper>minimum 4 caractères</clr-control-helper>
    </clr-password-container>
    <clr-password-container>
      <label>Confirmer Mot de passe *</label>
      <input clrPassword type="Password" placeholder="confimer votre mot de passe" ngModel name="repassword" required
        minlength="4" />
      <clr-control-error *clrIfError="'required'">Ce champ est obligatoire </clr-control-error>
      <clr-control-helper>minimum 4 caractères</clr-control-helper>
    </clr-password-container>
    <!-- <clr-radio-container clrInline>
      <label>Autorisation *</label>
      <clr-radio-wrapper>
        <input type="radio" clrRadio name="role"  value="user" ngModel required />
        <label>Utilisateur</label>
      </clr-radio-wrapper>
      <clr-radio-wrapper>
        <input type="radio" clrRadio name="role"  value="writer" ngModel required  />
        <label>Traiteur</label>
      </clr-radio-wrapper>
      <clr-radio-wrapper>
        <input type="radio" clrRadio name="role"  value="admin" ngModel required />
        <label>Admin</label>
      </clr-radio-wrapper>
      <clr-control-error>Ce champ est obligatoire!</clr-control-error>
    </clr-radio-container> -->
    <div class="clr-row">
      <div class="clr-col-md-2">
        <clr-input-container>
          <label>Image utilisateur *</label>
        </clr-input-container>
      </div>
      <div class="clr-col-md-6">
        <div class="img" *ngIf="url">
          <div class="card">
            <div class="card-img container">
              <img [src]="url">
            </div>
          </div>
        </div>
        <br>
        <cds-form-group layout="vertical">
          <cds-file>
            <label></label>
            <input type="file" (change)="picked($event)" />
          </cds-file>
        </cds-form-group>
        <br>
      </div>
    </div>
    <br><br>
    <div class="btn-sm btn-group btn-outline-primary">
      <button class="btn" routerLink="../">
        <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" fill="currentColor"
          class="bi bi-arrow-counterclockwise" viewBox="-1 -2 16 16">
          <path fill-rule="evenodd" d="M8 3a5 5 0 1 1-4.546 2.914.5.5 0 0 0-.908-.417A6 6 0 1 0 8 2v1z" />
          <path
            d="M8 4.466V.534a.25.25 0 0 0-.41-.192L5.23 2.308a.25.25 0 0 0 0 .384l2.36 1.966A.25.25 0 0 0 8 4.466z" />
        </svg> Retour
      </button>
      <button [clrLoading]="validateBtnState" type="submit" class="btn" *ngIf="!addNew &&  url && userForm.valid">
        <svg id="btn_plus" xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
          class="bi bi-plus" viewBox="-1 -4 16 16">
          <path
            d="M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4z" />
        </svg> Ajouter
      </button>
      <button [clrLoading]="validateBtnState" (click)="resetForm(userForm)" class="btn" *ngIf="addNew"
        [disabled]="userForm.invalid">
        <svg id="btn_plus" xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
          class="bi bi-plus" viewBox="-1 -4 16 16">
          <path
            d="M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4z" />
        </svg> Créer nouveau
      </button>
      <button class="btn" *ngIf="addNew" (click)="resetForm(userForm)">
        <clr-icon shape="times"></clr-icon> Annuler
      </button>
    </div>
  </form>
</section>