import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { RetenueService } from '../retenue.service';

@Component({
  selector: 'app-retenue-delete',
  templateUrl: './retenue-delete.component.html',
  styleUrls: ['./retenue-delete.component.css']
})
export class RetenueDeleteComponent implements OnInit {
  @Output() saved = new EventEmitter<boolean>()
  @Output() closed = new EventEmitter<boolean>()
  @Input() retenueObject: any

  alertError: boolean = false
  deleteAlert: boolean = false
  constructor(private retenueService: RetenueService, private router: Router) { }

  ngOnInit(): void {
  }

  deleteRetenue() {
    if (!this.deleteAlert) {
      this.retenueService.deleteRetenue(this.retenueObject.id).subscribe(
        data => {
          this.saved.emit(true)
          this.closed.emit(true)
          this.router.navigate(["/retenue/retenues"])
        },
        err => {
          this.alertError = true
        },
        () => this.saved.emit(true)
      )
    }
  }
  closeAction() {
    this.closed.emit(true)
  }

}
