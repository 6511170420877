import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { Abonnement } from 'src/app/setting/setting';
import { SettingService } from 'src/app/setting/setting.service';
import { Bank, Transaction } from 'src/app/user/user';

@Component({
  selector: 'app-abonnement-config',
  templateUrl: './abonnement-config.component.html',
  styleUrls: ['./abonnement-config.component.css']
})
export class AbonnementConfigComponent implements OnInit {
  @Output() abonnementId = new EventEmitter<any>();
  abonnement: Abonnement = new Abonnement()
  amount: number = 0
  nbMensuel: number = 1
  addAbonnement: boolean = false
  showMensuel: boolean = false
  transaction = new Transaction();
  selectedValue: number = 1;
  checkPicture: string;
  bank: Bank;
  nbInvoice: number = 0;
  nbQuotation: number = 0;
  nbUser: number = 2;



  constructor(private settingService: SettingService) { }

  ngOnInit(): void {
  }

  onCheckPlan($event) {
    this.amount = 0
    let plan = $event.target.value
    if (plan == 'true') {
      this.showMensuel = true
      this.abonnement.mensuel = true
      this.amount = 1000
    }
    if (plan == 'false') {
      this.showMensuel = false
      this.abonnement.annuel = true
      this.amount = 2000
    }
  }

  goldAbonnement() {
    this.amount = 0
    this.abonnement.premuim = true;
    if (this.abonnement.annuel) {
      this.amount += 500;
    } else {
      this.amount = this.amount + 70
    }
    //sthis.editAbonnement = false;
    this.addAbonnement = true;
    this.abonnement.facturation_caisse = false;
    this.abonnement.facturation_only = false
    this.abonnement.livraison = false
    //this.amount = this.amount + 1000
    this.amount = this.amount * this.nbMensuel
  }
  FacturationCaisse() {
    this.amount = 0
    this.abonnement.facturation_caisse = true;
    if (this.abonnement.annuel) {
      this.amount += 400;
    } else {
      this.amount = this.amount + 50
    }
    this.addAbonnement = true;
    this.abonnement.livraison = false;
    this.abonnement.premuim = false;
    //this.amount = this.amount + 700
    this.amount = this.amount * this.nbMensuel
  }
  FacturationOnly() {
    this.amount = 0
    this.abonnement.facturation_only = true;
    if (this.abonnement.annuel) {
      this.amount += 350;
    } else {
      this.amount = this.amount + 45
    }
    //document.cookie = "silver=" + (this.abonnement.facturation_caisse);
    this.addAbonnement = true;
    //this.amount = this.amount + 500
    this.amount = this.amount * this.nbMensuel
  }
  Livraison() {
    this.amount = 0
    this.abonnement.premuim = false
    this.abonnement.facturation_caisse = false
    this.abonnement.facturation_only = false
    this.abonnement.livraison = true
    if (this.abonnement.annuel) {
      this.amount += 350
    } else {
      this.amount += 50
    }
    this.addAbonnement = true
    this.amount = this.amount * this.nbMensuel
  }

  postAbonnement() {
    this.abonnement.transactions = []
    this.transaction.isCompleted = false
    this.transaction.sourceId = 0
    if (this.selectedValue == 2) {
      this.transaction.transactionTypeId = 4
      this.transaction.checkImage = this.checkPicture
      this.transaction.bankId = this.bank
    } else if (this.selectedValue == 1) {
      this.transaction.transactionTypeId = 2

    }
    this.transaction.amount = this.amount + (this.nbInvoice * 10) + (this.nbQuotation * 20) + ((this.nbUser - 2) * 40)
    this.abonnement.transactions.push(this.transaction)
    this.abonnement.nbMensuel = this.nbMensuel
    this.abonnement.nbInvoice = this.nbInvoice
    this.abonnement.nbQuotation = this.nbQuotation
    this.abonnement.nbUser = this.nbUser
    this.abonnementId.emit(this.abonnement);
  }

}
