import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { EtatCommandeService } from '../etat-commande.service';

@Component({
  selector: 'app-etat-commande-delete',
  templateUrl: './etat-commande-delete.component.html',
  styleUrls: ['./etat-commande-delete.component.css']
})
export class EtatCommandeDeleteComponent implements OnInit {

  @Input() etatCommandeId: any
  @Output() closed = new EventEmitter<boolean>()
  @Output() saved = new EventEmitter<boolean>()

  constructor(private etatCommandeService: EtatCommandeService) { }

  ngOnInit(): void {
  }

  close() {
    this.closed.emit(true)
  }

  save() {
    this.etatCommandeService.deleteEtatCommande(this.etatCommandeId).subscribe(
      data => {
        this.saved.emit(true)
      }
    )
  }
}
