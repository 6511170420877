import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { Timbre } from '../timbre';
import { TimbreService } from '../timbre-service.service';

@Component({
  selector: 'app-timbre-delete',
  templateUrl: './timbre-delete.component.html',
  styleUrls: ['./timbre-delete.component.css']
})
export class TimbreDeleteComponent implements OnInit {

  @Output() closed = new EventEmitter<boolean>()
  @Output() saved = new EventEmitter<boolean>();
  @Input() timbreObject: Timbre
  alertError: boolean;
  deleteAlert: boolean
  constructor(
    private timbreService: TimbreService,
    private router: Router
  ) { }

  ngOnInit(): void {
  }

  closeAction() {
    this.closed.emit(true);
  }

  deletetimbre() {
    if (!this.deleteAlert) {
      this.timbreService.DeleteTimbre(this.timbreObject.id).subscribe(
        data => {
          this.saved.emit(true);
          this.closed.emit(true)
          this.router.navigate(['/timbre/timbres'])
        },
        er => {
          this.alertError = true
        },
        () => this.saved.emit(true)
      )
    }
  }

}
