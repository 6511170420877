<div class="clr-row">
    <div class="clr-col-6">
        <div class="card center">
            <div class="card-block">
                <div class="card-title">
                    Nombre des utilisateurs
                </div>
                <div class="card-body">
                   {{countUser}}
                </div>
            </div>
        </div>
    </div>
    <div class="clr-col-6">
        <div class="card center">
            <div class="card-block">
                <div class="card-title">
                    Nombre des abonnements
                </div>
                <div class="card-body">
                    {{countAbonnement}}
                </div>
            </div>
        </div>
    </div>
</div>