import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { FilterDto } from 'src/app/filter.dto';
import { EtatCommande } from '../etat-commande';
import { EtatCommandeService } from '../etat-commande.service';

@Component({
  selector: 'app-etat-commande-edit',
  templateUrl: './etat-commande-edit.component.html',
  styleUrls: ['./etat-commande-edit.component.css']
})
export class EtatCommandeEditComponent implements OnInit {

  etatCommandeId: number
  etatCommande: EtatCommande = new EtatCommande()
  success: boolean = false
  stopLoading: boolean;
  alertSuccess: boolean;
  alertError: boolean;
  typeEtatCommande: string;

  constructor(private activatedRoute: ActivatedRoute, private router: Router,
    private etatCommandeService: EtatCommandeService) { }

  ngOnInit(): void {
    this.activatedRoute.params.subscribe(
      param => {
        this.etatCommandeId = + param["id"]
        this.getEtatCommande(this.etatCommandeId)
      }
    )
  }

  getEtatCommande(id: number) {
    let filter = new FilterDto()
    filter.where = { "id": id }
    this.etatCommandeService.getEtatsCommande(filter).subscribe(
      data => {
        this.etatCommande = data[0][0]
        this.typeEtatCommande = data[0][0].coefficient.toString()
        this.stopLoading=true
      },
      err => {
      console.error('Observer got an error: ' + err)
      },
    )
  }

  editEtatCommande(){
    this.etatCommande.coefficient=+this.typeEtatCommande    
    this.etatCommandeService.updateEtatCommande(this.etatCommandeId, this.etatCommande).subscribe(
      operation=>{
        this.stopLoading=true
        this.alertSuccess=true
      },
      err => {
      console.error('Observer got an error: ' + err),
      this.alertError=true
      },
    )
  }
}
