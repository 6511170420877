import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UserDemandsService } from '../service/user-demands.service';

@Component({
  selector: 'app-alert-delete',
  templateUrl: './alert-delete.component.html',
  styleUrls: ['./alert-delete.component.css']
})
export class AlertDeleteComponent implements OnInit {

  @Output() closed = new EventEmitter<boolean>()
  @Output() saved = new EventEmitter<boolean>()
  @Input() feedbackId : number

  constructor(private feedbackService :UserDemandsService ) { }

  ngOnInit(): void {
  }

  closeAction(){
    this.closed.emit(true)
  }
  deleteFeedback(){
      this.feedbackService.deleteFeedback(this.feedbackId).subscribe(
        data => {
          this.saved.emit(true)
        }
      )
  }

}
