import { Component, OnInit } from '@angular/core';
import { FilterDto } from '../filter.dto';
import { EtatCommande } from './etat-commande';
import { EtatCommandeService } from './etat-commande.service';

@Component({
  selector: 'app-etat-commande',
  templateUrl: './etat-commande.component.html',
  styleUrls: ['./etat-commande.component.css']
})
export class EtatCommandeComponent implements OnInit {

  etatsCommande: EtatCommande[] = []
  etatCommande: EtatCommande
  etatCommandeId: number
  showDetail: boolean = false
  showDelete: boolean = false

  constructor(private etatCommandeService: EtatCommandeService) { }

  ngOnInit(): void {
    this.getEtatsCommande()
  }

  getEtatsCommande() {
    let filter =new FilterDto
    filter.where = { "active": true }
    this.etatCommandeService.getEtatsCommande(filter).subscribe(
      data => {
        this.etatsCommande = data[0]
      }
    )
  }

  detail(item: EtatCommande) {
    this.etatCommande = item
    this.showDetail = true
  }

  delete(item: EtatCommande) {
    this.showDelete = true
    this.etatCommandeId = item.id
  }

  refresh() {
    this.getEtatsCommande()
    this.showDelete = false
  }
}
