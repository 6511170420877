import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { FilterDto } from 'src/app/filter.dto';
import { Retenue } from '../retenue';
import { RetenueService } from '../retenue.service';

@Component({
  selector: 'app-retenue-edit',
  templateUrl: './retenue-edit.component.html',
  styleUrls: ['./retenue-edit.component.css']
})
export class RetenueEditComponent implements OnInit {

  retenueObject: Retenue = new Retenue()
  success: boolean = false
  failed: boolean = false
  id: number
  constructor(private retenueService: RetenueService, private activatedRoute: ActivatedRoute) { }

  ngOnInit(): void {
    this.activatedRoute.params.subscribe(
      data => {
        this.id = data.id
        let filter = new FilterDto()
        filter.where = { "id": this.id, "active": true }
        this.getRevenueData(filter);
      }
    )
  }
  getRevenueData(filter: FilterDto) {
    this.retenueService.getRetenues(filter).subscribe(
      data => {
        this.retenueObject = data[0][0]
      }
    )
  }
  submitAction() {
    this.retenueService.updateRetenue(this.id, this.retenueObject).subscribe(
      data => {
        this.success = true
      },
      err => {
        this.failed = true
      }
    )
  }

}
