import { Abonnement } from "../setting/setting";
import { Contact } from "./user";

export class Entreprise {
    constructor(
        public id?: number,
        public email?: string,
        public name?: string,
        public password?: string,
        public resToken?: string,
        public role?: string,
        public logo?: string,
        public active?: boolean,
        public contactId?: Contact,
        public oldPassword?: string,
        public repassword?: string,
        public adressId?: Address,
        public createdAt?: string,
        public updatedAt?: string,
        public saltRounds?: string,
        public abonnementId?: Abonnement | null,
        public tel?: string,
    ) {
    }
}
export class Address {
    constructor(
        public id?: number,
        public city?: string,
        public state?: string,
        public street?: string,
        public number?: number,
        public geoCode?: number,
        public updatedAt?: Date,
        public createdAt?: Date,
        public active?: boolean,
        public providerId?: number,
        public userId?: number,
        public customerId?: number,
        public entrepriseId?: number

    ) { }
}

export class PostEntrepriseDto {
    constructor(
        public id?: number,
        public name?: string,
        public email?: string,
        public oldPassword?: string,
        public password?: string,
        public repassword?: string,
        public abonnementId?: Abonnement[] | number,
        public logo?: string,
        public active?: boolean,
        public contactId?: Contact,
        public role?: string,
    ) { }
}