<section>
  <h3>Détails devise</h3>
  <br>
  <div class="clr-row">
    <div class="clr-col-3">
      <span><b>Id</b></span><br>
      <span><b>Nom</b></span><br>
      <span><b>Code</b></span><br>
    </div>
    <div class="clr-col">
      <span>{{devise.id}}</span><br>
      <span>{{devise.name}}</span><br>
      <span>{{devise.code}}</span><br>
      <br>
    </div>
  </div>
  <br>

  <div class="btn-group btn-sm btn-outline-primary ">
    <button class="btn" routerLink="../../">
      <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" fill="currentColor"
        class="bi bi-arrow-counterclockwise" viewBox="-1 -2 16 16">
        <path fill-rule="evenodd" d="M8 3a5 5 0 1 1-4.546 2.914.5.5 0 0 0-.908-.417A6 6 0 1 0 8 2v1z" />
        <path d="M8 4.466V.534a.25.25 0 0 0-.41-.192L5.23 2.308a.25.25 0 0 0 0 .384l2.36 1.966A.25.25 0 0 0 8 4.466z" />
      </svg> Retour
    </button>
    <button class="btn" routerLink="/devise/devises/edit-devise/{{devise.id}}">
      <clr-icon shape="pencil"></clr-icon> Modifier
    </button>
    <button class="btn" (click)="deleteAction()">
      <clr-icon shape="trash"></clr-icon> Supprimer
    </button>
  </div>
</section>
<app-delete-devise *ngIf="showAlert" (closed)="closeAction()" [deviseObject]="devise"></app-delete-devise>