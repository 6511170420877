import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { FilterDto } from '../filter.dto';
import { Configuration } from '../setting/setting';
import { admin, User } from '../user/user';

@Injectable({
  providedIn: 'root'
})
export class CoreService {
  searchString: string;
  constructor(private http: HttpClient) { }

  getUser(filter: FilterDto<User>): Observable<User> {
    return this.http.get(environment.api + '/users?filter=' + JSON.stringify(filter)) as Observable<User>;
  }

  getconfiguration(filter: FilterDto<Configuration>): Observable<Configuration> {
    return this.http.get(environment.api + '/configurations?filter=' + JSON.stringify(filter)) as Observable<Configuration>;
  }

  getAdminInfo(): Observable<admin> {
    return this.http.get(environment.api + '/admins');
  }

  sendSearchStream(data: string) {
    this.searchString = data;
  }

  getSearchStream() {
    return this.searchString;
  }

}
