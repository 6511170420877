import { Component, OnInit } from '@angular/core';
import { tokenGetter } from 'src/app/user/user.service';
import jwt_decode from "jwt-decode";
import { ConfigService } from 'src/app/shared/config/config.service';
import { Router } from '@angular/router';
import { FilterDto } from 'src/app/filter.dto';
import { Configuration } from 'src/app/setting/setting';
import { CoreService } from '../core.service';


@Component({
  selector: 'app-side-nav',
  templateUrl: './side-nav.component.html',
  styleUrls: ['./side-nav.component.css']
})
export class SideNavComponent implements OnInit {
  abn: boolean;
  triale: boolean;
  userRole: string
  show: boolean = true;
  filter: FilterDto<Configuration> = new FilterDto<Configuration>()
  nameComplet: string;
  nameCourt: string;
  pictureConfig: string;
  silver: boolean = false;
  showCmd: boolean = true;
  showLivraison: boolean;
  showabnLivraison: boolean;
  showabnCompany: boolean;
  constructor(private configService: ConfigService, private router: Router, private serviceCore: CoreService) {
    this.userRole = jwt_decode(tokenGetter())["role"];
  }

  ngOnInit() {
    this.getConfiguration();
  }

  getConfiguration() {
    this.serviceCore.getAdminInfo().subscribe(
      data => {
        this.nameComplet = data[0]?.name;
        this.pictureConfig = data[0]?.logo
      }
    )
  }
}
