<section class="title">
    <h3 class="welcome">Retenues à la source </h3>
    <clr-datagrid (clrDgRefresh)="refresh($event)">
        <clr-dg-action-bar>
            <div class="btn-group large-screen">
                <button type="button" class="btn btn-sm btn-success" (click)="addRetenue()">
                    <svg id="btn_plus" xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                        class="bi bi-plus" viewBox="-1 -4 16 16">
                        <path
                            d="M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4z" />
                    </svg>
                    Créer nouveau
                </button>
            </div>
        </clr-dg-action-bar>
        <clr-dg-column [clrDgField]="'id'" style="background-color: #d0edb8 !important;">
            <ng-container *clrDgHideableColumn="{hidden: false}">
                Id
            </ng-container>
        </clr-dg-column>
        <clr-dg-column [clrDgField]="'name'" style="background-color: #d0edb8 !important;">
            Nom
        </clr-dg-column>
        <clr-dg-column [clrDgField]="'value'" style="background-color: #d0edb8 !important;">
            Pourcentage
        </clr-dg-column>
        <clr-dg-row *ngFor="let item of retenues" [clrDgItem]="item">
            <clr-dg-action-overflow>
                <button class="action-item" routerLink="/retenue/retenues/retenue-detail/{{item.id}}">Détails</button>
                <button class="action-item"
                    routerLink="/retenue/retenues/retenue-edit/{{item.id}}">Modification</button>
                <button class="action-item" (click)="deleteAction(item)">Suppression</button>
            </clr-dg-action-overflow>
            <clr-dg-cell class="cursor-pointer">{{item.id}}</clr-dg-cell>
            <clr-dg-cell class="cursor-pointer">{{item.name}}</clr-dg-cell>
            <clr-dg-cell class="cursor-pointer">{{item.value}}</clr-dg-cell>
        </clr-dg-row>
        <clr-dg-footer>
            <clr-dg-column-toggle>
                <clr-dg-column-toggle-title>Visualisation de colonnes</clr-dg-column-toggle-title>
                <clr-dg-column-toggle-button>Tous selectionner</clr-dg-column-toggle-button>
            </clr-dg-column-toggle>
            <clr-dg-pagination #pagination [clrDgTotalItems]="count">
                {{pagination.firstItem + 1}} - {{pagination.lastItem + 1}}
                de {{count}}
            </clr-dg-pagination>
        </clr-dg-footer>
    </clr-datagrid>
</section>
<app-retenue-delete [retenueObject]="retenueObject" (saved)="refresh($event)" (closed)="showDeleteAlert = false"
    *ngIf="showDeleteAlert"></app-retenue-delete>