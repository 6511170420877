<section>
    <h3> Ajouter Taxe</h3>
    <div *ngIf="showAlert" class="alert alert-success" role="alert">
        <div class="alert-items">
            <div class="alert-item static">
                <div class="alert-icon-wrapper">
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                        class="bi bi-check-circle" viewBox="0 0 16 16">
                        <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
                        <path
                            d="M10.97 4.97a.235.235 0 0 0-.02.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-1.071-1.05z" />
                    </svg>
                </div>
                <span class="alert-text">
                    {{ 'commun.jd' | translate }}
                </span>
            </div>
        </div>
    </div>
    <br>
    <form clrForm (ngSubmit)="submit(taxForm.value,taxForm)" #taxForm="ngForm">
        <clr-input-container>
            <label> nom </label>
            <input clrInput type="text" name="name" ngModel required />
            <clr-control-error> ce champs est obligatoire </clr-control-error>
        </clr-input-container>
        <clr-input-container>
            <label> Pourcentage %</label>
            <input clrInput type="number" name="percentage" ngModel required />
            <clr-control-error> ce champs est obligatoire</clr-control-error>
        </clr-input-container>
        <clr-textarea-container>
            <label> Description</label>
            <textarea clrTextarea name="description" ngModel></textarea>
        </clr-textarea-container>
        <br>
        <div class="btn-sm btn-group btn-outline-primary">
            <button class="btn" routerLink="../">
                <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" fill="currentColor"
                    class="bi bi-arrow-counterclockwise" viewBox="-1 -2 16 16">
                    <path fill-rule="evenodd" d="M8 3a5 5 0 1 1-4.546 2.914.5.5 0 0 0-.908-.417A6 6 0 1 0 8 2v1z" />
                    <path
                        d="M8 4.466V.534a.25.25 0 0 0-.41-.192L5.23 2.308a.25.25 0 0 0 0 .384l2.36 1.966A.25.25 0 0 0 8 4.466z" />
                </svg> Retour
            </button>
            <button type="submit" class="btn" *ngIf="!creerNouveau" [disabled]="taxForm.invalid">
                <svg id="btn_plus" xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                    class="bi bi-plus" viewBox="-1 -4 16 16">
                    <path
                        d="M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4z" />
                </svg> Ajouter
            </button>
            <button type="submit" class="btn" (click)="resetForm(taxForm)" *ngIf="creerNouveau"
                [disabled]="taxForm.invalid">
                <svg id="btn_plus" xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                    class="bi bi-plus" viewBox="-1 -4 16 16">
                    <path
                        d="M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4z" />
                </svg> Créer nouveau
            </button>
        </div>
    </form>
</section>