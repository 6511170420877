import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { FilterDto } from 'src/app/filter.dto';
import { Configuration } from 'src/app/setting/setting';
import { tokenGetter } from 'src/app/user/user.service';
import { CoreService } from '../core.service';
import jwt_decode from "jwt-decode";
import { User } from 'src/app/user/user';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit {
  name: string;
  picture: string;
  nameComplet: string;
  nameCourt: string;
  pictureConfig: string;
  filter: FilterDto<Configuration> = new FilterDto<Configuration>()
  filter1: FilterDto<User> = new FilterDto<User>()
  id: number;
  globaleDiscount: boolean;
  promo: boolean;
  email: string;
  sendMail: boolean;
  mailbox: string;
  userRole: string;
  matricule: number;
  tel: number;
  adresseville: string;
  open: boolean;

  constructor(private router: Router, private serviceCore: CoreService) {
    window.addEventListener('refreshHeader', (evt: any) => {
      if (evt.detail == true) {
        //this.getUser()
      }
    });
    this.userRole = jwt_decode(tokenGetter())["role"];
  }

  ngOnInit() {
    this.getConfiguration()
  }

  getCookie(cname: string) {
    var name = cname + "=";
    var decodedCookie = decodeURIComponent(document.cookie);
    var ca = decodedCookie.split(';');
    for (var i = 0; i < ca.length; i++) {
      var c = ca[i];
      while (c.charAt(0) == ' ') {
        c = c.substring(1);
      }
      if (c.indexOf(name) == 0) {
        return c.substring(name.length, c.length);
      }
    }
    return "";
  }
  ngOnChanges() {
    //this.getUser();
  }

  getUser() {
    this.filter1.select = ["picture", "name"]
    this.filter1.where = { "id": this.id }
    this.serviceCore.getUser(this.filter1).subscribe(
      data => {
        // commented by Dali 
        // this.picture = data[0][0].picture; 
        //this.name = data[0][0].name;
        // document.cookie = "pictureUser=" + (this.picture);
        // document.cookie = "nameUser=" + (this.name);
      }
    )
  }

  getConfiguration() {
    this.serviceCore.getAdminInfo().subscribe(
      data => {
        this.nameComplet = data[0]?.name;
        this.pictureConfig = data[0]?.logo
      }
    )
  }

  logout() {
    this.router.navigate(['/user/login']);
    var allCookies = document.cookie.split(';');
    allCookies.forEach(element => {
      if (!element.includes('loginUser') && !element.includes('iocId') && !element.includes('nameUser')
        && !element.includes('pictureUser') && !element.includes('nameComplet')
        && !element.includes('nameCourt') && !element.includes('showLivraison')) {
        document.cookie = element + "=;expires=" + new Date(0).toUTCString();
      }
    });
  }

  async onSearchChange(args: string) {
    if (window.location.pathname != '/search') {
      await this.router.navigate(['/search']);
    }
    this.serviceCore.sendSearchStream(args)
    var event = new CustomEvent('findAction', { 'detail': args });
    window.dispatchEvent(event);
  }
  btnToggle() {
    this.open = true
  }
}