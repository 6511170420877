import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { FeedBack } from '../feedback';
import { environment } from 'src/environments/environment';
import { FilterDto } from 'src/app/filter.dto';

@Injectable({
  providedIn: 'root'
})
export class UserDemandsService {

  constructor(private http : HttpClient) { }

  getAllFeedbacks(filter : FilterDto) : Observable<[FeedBack[],number]>{
    return this.http.get(environment.api+"/feedbacks?filter="+JSON.stringify(filter)) as Observable<[FeedBack[],number]>
  }

  updateFeedBack(id : number, model : FeedBack):Observable<any>{
    return this.http.patch<FeedBack>(environment.api+"/feedback/"+JSON.stringify(id),model)
  }

  deleteFeedback(id : number): any{
    return this.http.delete(environment.api+"/feedback/"+JSON.stringify(id))
  }
}
