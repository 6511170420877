import { Abonnement } from "../setting/setting";

export class User {
  constructor(
    public id?: number,
    public name?: string,
    public email?: string,
    public oldPassword?: string,
    public password?: string,
    public repassword?: string,
    public picture?: string,
    public active?: boolean,
    public resToken?: string,
    public contactId?: Contact,
    public abonnementId?: Abonnement[] | number,
    public role?: string,
    public createdBy?: number,
    public createdAt?: string,
    public updatedBy?: number

  ) { }
}
export class PostUser {
  constructor(
    public id?: number,
    public name?: string,
    public email?: string,
    public oldPassword?: string,
    public password?: string,
    public repassword?: string,
    public abonnementId?: Abonnement[] | number,
    public picture?: string,
    public active?: boolean,
    public contactId?: Contact,
    public role?: string,
  ) { }
}

export class admin {
  public id?: number;
  public name?: string;
  public email?: string;
  public password?: string;
  public active?: boolean;
  public pictrue?: string
};
export class PictureObject {
  picture: string;
  url: string;
  url2: string;
  constructor(
    public base64Data?: string,
  ) { }
}

export class PictureAccount {
  constructor(
    public url?: string,
    public cloudName?: string,
    public apiKey?: string,
    public apiSecret?: string,
  ) { }
}



export class Contact {
  public id?: number;
  public firstName?: string
  public lastName?: string;
  public tel?: number;
  public fax?: number;
  public email?: string;
  public createdAt?: string;
  public updatedAt?: string;
  public createdBy?: number;
  public active?: boolean;
  public providerId?: number;
  public customerId?: number;
  public carrierId?: number;
  public userId?: number;
  public updatedBy?: number;

}
export class Transaction {
  constructor(
    public id?: number,
    public amount?: number,
    public isCompleted?: boolean,
    public isCredit?: boolean,
    public sourceId?: number,
    public ban?: string,
    public checkNumber?: string,
    public checkImage?: string,
    public dueDate?: Date,
    public isOver?: boolean,
    public operationNumber?: number,
    public createdAt?: string,
    public createdBy?: number,
    public active?: boolean,
    public description?: string,
    public updatedAt?: string,
    public name?: string,
    public updatedBy?: number,
    public ordreNumber?: number,
    public cratesId?: number,
    public transactionTypeId?: number,
    public customerId?: number | Customer,
    public userId?: number,
    public partnerId?: number,
    public providerId?: number,
    public orderId?: number,
    public bankId?: number | Bank,
    public buyingId?: number,
    public planningId?: number,
    public real_id?: number
    // public adressId?: number | Address,  
    // public costTypeId?:number | CostsType
  ) { }
}
export class Bank {
  constructor(
    public id?: number,
    public name?: string,
    public account?: string,
    public createdAt?: string,
    public createdBy?: number,
    public updatedAt?: string,
    public updatedBy?: number,
    public active?: boolean,

  ) { }
}
export class Customer {
  constructor(
    public id?: number,
    public name?: string,
    public mf?: string,
    public description?: string,
    public customerType?: string,
    public picture?: string,
    public createdBy?: number,
    public createdAt?: string,
    public updatedAt?: string,
    public updatedBy?: number,
    public active?: boolean,
    public contacts?: Contact[],
    // public adress?: Address[],
    public ville?: string,
  ) { }
}