import { Address, Entreprise } from "../user/Entreprise";

export class partner {
    constructor(
        public id?: number,
        public name?: string,
        public email?: string,
        public password?: string,
        public abonnementId?: number,
        public typePartenariat?: string,
        public activationId?: string,
        public resetPass?: string,
        public mf?: string,
        public active?: boolean,
        public role?: string,
        public tel?: string,
        public codeInvitation?: string,
        public createdAt?: string,
        public updatedAt?: string,
        public entreprises?: Entreprise[],
        public adress?: Address[]
    ) { }
}