import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { FilterDto } from 'src/app/filter.dto';
import { Retenue } from '../retenue';
import { RetenueService } from '../retenue.service';

@Component({
  selector: 'app-retenue-detail',
  templateUrl: './retenue-detail.component.html',
  styleUrls: ['./retenue-detail.component.css']
})
export class RetenueDetailComponent implements OnInit {

  retenue: Retenue = new Retenue()
  showAlert: boolean = false
  id: number
  constructor(private retenueService: RetenueService, private activatedRoute: ActivatedRoute) { }

  ngOnInit(): void {
    this.activatedRoute.params.subscribe(
      data => {
        this.id = data.id
        let filter = new FilterDto()
        filter.where = { "id": this.id, "active": true }
        this.getRetenueDetail(filter)
      }
    )
  }
  getRetenueDetail(filter: FilterDto) {
    this.retenueService.getRetenues(filter).subscribe(
      data => {
        this.retenue = data[0][0]
      }
    )
  }
  deleteAction() {
    this.showAlert = true
  }
  closeAction() {
    this.showAlert = false
  }

}
