<clr-header class="header" style="background-color: #3c8500;">
    <div class="branding">
        <a routerLink="dashboard" class="nav-link">
            <img type="image/icon" class="icon" src="assets\inveep.png" width="150" height="45px" alt="clr-icon" />
        </a>
    </div>
    <div class="header-nav">
        <a routerLink="dashboard" class="active nav-link nav-text">Dashboard</a>
    </div>
    <div class="clr-align-self-center">
        <form class="search size-style">
            <label for="search_input">
                <input id="search_input" type="text" placeholder="Rechercher..."
                    (input)="onSearchChange($event.target.value)">
            </label>
        </form>
    </div>
    <div class="header-actions header-actions--overflow">
        <clr-dropdown [clrCloseMenuOnItemClick]="false" style="padding-top: 10px;">
            <span class="label">
                <button clrDropdownTrigger aria-label="Dropdown demo button">
                    <clr-icon shape="user"></clr-icon>
                    <span class="nav-text">{{nameComplet}} {{nameCourt}}</span>
                </button>
            </span>
            <clr-dropdown-menu *clrIfOpen>
                <button class="btn btn-link" (click)="logout()">Déconnexion</button>
            </clr-dropdown-menu>
        </clr-dropdown>
    </div>
</clr-header>