export class FeedBack {
    constructor(
      public id?: number,
      public email?: string,
      public content?: string, 
      public tel?:string,
      public treated?:boolean,
      public createdAt?: Date,
      public createdBy?: number,
      public entrepriseId?: number,
      public byAdmin?: boolean,
    ) { }
  }