export class Devise {
    public id: number;
    public name?: string;
    public code?: string;
    public active?: boolean
}

export class PostDevise {
    public name?: string;
    public code?: string;
}