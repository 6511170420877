import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { FilterDto } from 'src/app/filter.dto';
import { partner } from '../partner';
import { PartnerService } from '../partner.service';

@Component({
  selector: 'app-partner-edit',
  templateUrl: './partner-edit.component.html',
  styleUrls: ['./partner-edit.component.css']
})
export class PartnerEditComponent implements OnInit {

  showConfigAbonnement: boolean = false
  partner: partner = new partner()
  id: number
  success = false
  constructor(private activatedRoute: ActivatedRoute, private router: Router,
    private partnerService: PartnerService) { }

  ngOnInit(): void {
    this.activatedRoute.params.subscribe(
      (p: Params) => {
        this.id = +p["id"]
      }
    )
    this.getPartnerInfo();
  }
  getPartnerInfo() {
    let filter = new FilterDto()
    filter.where = { "id": this.id, "active": true }
    filter.relations = ["abonnementId"]
    this.partnerService.getAllPartners(filter).subscribe(
      data => {
        this.partner = data[0][0]
      }
    )
  }
  submit() {
    this.partnerService.updatePartner(this.partner.id, this.partner).subscribe(
      data => {
        this.success = true
        setTimeout(() => {
          this.router.navigate(["/partner/partners"])
        }, 2000);
      }
    )
  }

}
