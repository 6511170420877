import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { Login } from './login/login';
import { HttpClient } from '@angular/common/http'
import { FilterDto } from '../filter.dto';
import { User, PostUser, Contact, admin } from './user';
import { Abonnement } from '../setting/setting';
import { Entreprise } from './Entreprise';
import jwtDecode from 'jwt-decode';

export function getValueFromToken(value : string){
  const token = tokenGetter()
  return jwtDecode(token)[value]
}

export function tokenGetter() {
  var name = "token=";
  var decodedCookie = decodeURIComponent(document.cookie);
  var ca = decodedCookie.split(';');
  for (var i = 0; i < ca.length; i++) {
    var c = ca[i];
    while (c.charAt(0) == ' ') {
      c = c.substring(1);
    }
    if (c.indexOf(name) == 0) {
      return c.substring(name.length, c.length);
    }
  }
  return "";
}
@Injectable({
  providedIn: 'root'
})
export class UserService {

  constructor(private http: HttpClient) { }

  login(user: Login): Observable<any> {
    return this.http.post<Login>(environment.api + '/auth/login-admin', user)
  }
  checkAdmin(): Observable<number | undefined> {
    return this.http.get(environment.api + '/getAdmins') as Observable<number | undefined>;
  }
  createAdmins(admin: admin): Observable<any> {
    return this.http.post<admin>(environment.api + '/createAdmin', admin)
  }
  
  getEntreprise(filter: FilterDto<Entreprise>): Observable<[Entreprise[], number]> {
    return this.http.get(environment.api + '/entreprises?filter=' + JSON.stringify(filter)) as Observable<[Entreprise[], number]>
  }
  getUser(filter: FilterDto<User>): Observable<[User[], number]> {
    return this.http.get(environment.api + '/users?filter=' + JSON.stringify(filter)) as Observable<[User[], number]>;
  }
  getAbonnement(filter: FilterDto<Abonnement>): Observable<[Abonnement[], number]> {
    return this.http.get(environment.api + '/abonnement/abonnement?filter=' + JSON.stringify(filter)) as Observable<[Abonnement[], number]>;
  }

  VerifResetPaswword(id: number, user: PostUser): Observable<any> {
    return this.http.post(environment.api + '/user-reset-pwd/' + JSON.stringify(id), user) as Observable<any>;
  }

  addContact(contact: Contact): Observable<Contact> {
    return this.http.post(environment.api + '/contact', contact) as Observable<Contact>;
  }
  // addAdress(adress:Address): Observable<Address> {
  //   return this.http.post(environment.api +'/adress',adress) as Observable<Address>;
  // }

  // 
  getAllUsers(filter: FilterDto<User>): Observable<[User[], number]> {
    return this.http.get(environment.api + '/users?filter=' + JSON.stringify(filter)) as Observable<[User[], number]>;
  }
  editUserById(ID: number, userObject: User): Observable<User> {
    return this.http.patch<User>(environment.api + '/user-shop/' + JSON.stringify(ID), userObject);
  }
  editAbonnement(id: number, abn: Abonnement): Observable<Abonnement> {
    return this.http.patch<Abonnement>(environment.api + '/abonnement/abonnement/' + JSON.stringify(id), abn) as Observable<Abonnement>;
  }
  editEntreprise(id: number, entrep: Entreprise): Observable<Entreprise> {
    return this.http.patch<Entreprise>(environment.api + '/entreprise/' + JSON.stringify(id), entrep);
  }
}
