<clr-main-container class="main-container">
  <app-header *ngIf="showMenu"></app-header>
  <div class="content-container">
    <div class="content-area espace">
      <router-outlet></router-outlet>
    </div>
    <clr-vertical-nav class="navTaille" [clrVerticalNavCollapsible]="true" [clrVerticalNavCollapsed]="clrVerticalNavCollapsed" [clr-nav-level]="1"
      *ngIf="showMenu" >
      <app-side-nav *ngIf="showMenu"></app-side-nav>
    </clr-vertical-nav>
  </div>
</clr-main-container>