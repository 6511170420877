import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FeedBack } from '../feedback';

@Component({
  selector: 'app-demand-detail',
  templateUrl: './demand-detail.component.html',
  styleUrls: ['./demand-detail.component.css']
})
export class DemandDetailComponent implements OnInit {

  @Input() feedbackObject : FeedBack
  @Output() closed = new EventEmitter<boolean>()
  constructor() { }

  ngOnInit(): void {
  }

  CloseAction(){
    this.closed.emit(true)
  }

}
