import { Component} from '@angular/core';


@Component({
  selector: 'app-search',
  templateUrl: './search.component.html',
  styleUrls: ['./search.component.css']
})
export class SearchComponent{
  findAction: boolean;
  constructor() {
    window.addEventListener('findAction', (evt: any) => {
        var eventFind = new CustomEvent('find', { 'detail': evt.detail });
        window.dispatchEvent(eventFind);
        this.findAction=true
    });
  }


}
