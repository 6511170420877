<section>
    <h3>Recherche</h3>
    <br>  
<clr-tabs>
    <clr-tab>
        <button clrTabLink>Produits</button>
        <clr-tab-content>
            <br>
            <app-product *ngIf="findAction"></app-product>
        </clr-tab-content>
    </clr-tab>
    <clr-tab>
        <button clrTabLink>Clients</button>
        <clr-tab-content>
            <br>
            <app-customer *ngIf="findAction"></app-customer>
        </clr-tab-content>
    </clr-tab>
    <clr-tab>
        <button clrTabLink>Fournisseurs</button>
        <clr-tab-content>
            <br>
            <app-provider *ngIf="findAction"></app-provider>
        </clr-tab-content>
    </clr-tab>
</clr-tabs>
</section>


