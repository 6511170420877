import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuard } from '../auth/auth.guard';
import { RoleGuard } from '../auth/role.guard';
import { LoginComponent } from './login/login.component';
import { ResetPasswordComponent } from './reset-password/reset-password.component';
import { SignInComponent } from './sign-in/sign-in.component';
import { AddUserComponent } from './user-management/add-user/add-user.component';
import { DeleteUserComponent } from './user-management/delete-user/delete-user.component';
import { DetailUserComponent } from './user-management/detail-user/detail-user.component';
import { EditUserComponent } from './user-management/edit-user/edit-user.component';
import { UserManagementComponent } from './user-management/user-management.component';

const routes: Routes = [
  { path: 'login', component: LoginComponent,canActivate: [AuthGuard]},
  { path: 'reset-password', component: ResetPasswordComponent},
  { path: 'sign-in', component: SignInComponent},
  { path: 'users', component: UserManagementComponent,data: {roles: ['admin', 'user']},canActivate: [AuthGuard,RoleGuard]},
  { path: 'users/edit-user/:id', component: EditUserComponent,data: {roles: ['admin', 'user']},canActivate: [AuthGuard,RoleGuard]},
  { path: 'users/add-user', component: AddUserComponent,data: {roles: ['admin', 'user']},canActivate: [AuthGuard,RoleGuard]},
  { path: 'users/delete-user', component: DeleteUserComponent,data: {roles: ['admin', 'user']},canActivate: [AuthGuard,RoleGuard]},
  { path: 'users/detail-user/:id', component: DetailUserComponent,data: {roles: ['admin', 'user']},canActivate: [AuthGuard,RoleGuard]}

];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class UserRoutingModule { }
