<div class="modal">
  <div class="modal-dialog" role="dialog" aria-hidden="true">
    <div class="modal-content">
      <div class="modal-header">
        <h3 class="modal-title">Suppréssion d'utilisateur</h3>
        <svg xmlns="http://www.w3.org/2000/svg" style="cursor: pointer;" (click)="closeAction()" width="20" height="20"
          fill="currentColor" class="bi bi-x-lg" viewBox="0 0 16 16">
          <path
            d="M2.146 2.854a.5.5 0 1 1 .708-.708L8 7.293l5.146-5.147a.5.5 0 0 1 .708.708L8.707 8l5.147 5.146a.5.5 0 0 1-.708.708L8 8.707l-5.146 5.147a.5.5 0 0 1-.708-.708L7.293 8 2.146 2.854Z" />
        </svg>
      </div>
      <br>
      <div class="alert alert-danger" role="alert" *ngIf="alertError">
        <div class="alert-items">
          <div class="alert-item static">
            <div class="alert-icon-wrapper">
              <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                class="bi bi-check-circle" viewBox="0 0 16 16">
                <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
                <path
                  d="M10.97 4.97a.235.235 0 0 0-.02.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-1.071-1.05z" />
              </svg>
            </div>
            <span class="alert-text">Échec de la suppression d'utilisateur </span>
          </div>
        </div>
      </div>
      <div class="modal-body">
        <clr-alert [clrAlertType]="'danger'" [clrAlertClosable]="false" *ngIf="!alertError && !deleteAlert">
          <clr-alert-item>
            <span class="alert-text">
              Veuillez-vous vraiment supprimer l'utilisateur N° {{userObject.id}} !
            </span>
          </clr-alert-item>
        </clr-alert>
      </div>
      <div class="modal-footer">
        <button class="btn btn-outline" type="button" (click)="closeAction()">Annuler</button>
        <button class="btn btn-primary" type="button" (click)="deleteUser()">Supprimer</button>
      </div>
    </div>
  </div>
</div>
<div class="modal-backdrop" aria-hidden="true"></div>
