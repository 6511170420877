import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { FilterDto } from 'src/app/filter.dto';
import { User } from '../user';
import { UserService } from '../user.service';

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.css']
})
export class ResetPasswordComponent implements OnInit {

  user :User = new User()
  year:number
  isEqual:boolean=true
  alertSuccess:boolean=false
  alertError: boolean;
  token: string;
  userId: number;
  hasAcces: boolean;


  constructor(private router:Router,private userService:UserService) { }

  ngOnInit(): void {
    let date = new Date()
    this.year = date.getFullYear();
    const array = this.router.url.split("id");
    let tokenarray = array[0].split("=");
    this.token=tokenarray[1] 
   this.userId= + array[1]
   this.getUser(this.userId)
  }
  changePwd(){
   if(this.user.password==this.user.repassword && this.hasAcces){
    this.userService.editUserById(this.user.id, this.user).subscribe(
      data => {
        this.alertSuccess = true
      },
      err => {
        this.alertError=true
      },
    )
   }

  }

  getUser(id:number) {
    let filter: FilterDto = new FilterDto()
    filter.relations = ["contactId"]
    filter.where={id:id}
    this.userService.getAllUsers(filter).subscribe(
      user => {
        this.user = user[0][0]
        if(this.user){
          if(this.user.resToken==this.token){
              this.hasAcces=true
          }
        }
      },
    )
  }

}
