import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Tax } from '../tax';
import { TaxService } from '../tax.service';

@Component({
  selector: 'app-tax-edit',
  templateUrl: './tax-edit.component.html',
  styleUrls: ['./tax-edit.component.css']
})
export class TaxEditComponent implements OnInit {

  showAlert: boolean;
  showMsg: boolean;
  tax: Tax;
  id: number;

  constructor(private taxService: TaxService, private activatedRoute: ActivatedRoute) { }

  ngOnInit() {
    this.activatedRoute.paramMap.subscribe(params => {
      this.id = +params.get('id');
      this.taxService.getTaxById(this.id)
        .subscribe(
          data => {
            this.tax = data
          })
    });
  }

  delete() {
    this.showAlert = true;
  }

  close() {
    this.showAlert = false;
  }

  submit(tax: Tax) {
    this.taxService.editTax(this.id, tax)
      .subscribe(
        data => {
          this.showAlert = true;
        })
  }


}
