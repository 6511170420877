import { Component, OnInit } from '@angular/core';
import { User } from '../user';
// import { QuotationService } from '../../sales/quotation/quotation.service';
import { FilterDto } from 'src/app/filter.dto';
import { ClrDatagridStateInterface } from '@clr/angular';
import { FilterService } from 'src/app/shared/filter.service';
import { ConfigService } from 'src/app/shared/config/config.service';
import { Router } from '@angular/router';
import { UserService } from '../user.service';
import { Entreprise } from '../Entreprise';

@Component({
  selector: 'app-user-management',
  templateUrl: './user-management.component.html',
  styleUrls: ['./user-management.component.css']
})
export class UserManagementComponent implements OnInit {

  userArray: Entreprise[] = []
  showAlert: boolean;
  filter: FilterDto = new FilterDto()
  count: number;
  userObject = new User();
  userId: number;
  openUserFilter: boolean;
  nameUser: boolean;
  nbUser: number;
  alertUser: boolean;
  nbCount: number;
  triale: boolean;
  abonnee: boolean;
  total: number
  pageSize: number = 5
  currentPage: number = 1
  nbr_page: number = 1
  constructor(
    // private quotationService: QuotationService,
    private serviceUser: UserService,
    private filterService: FilterService,
    private configService: ConfigService, private router: Router) { }

  ngOnInit() {
    this.userId = +this.configService.getCookie('iocId')
    this.getEntreprise();
  }

  refresh(state: ClrDatagridStateInterface) {
    this.filter = this.filterService.bind(state);
    this.filter.relations = ["contactId"]
    this.filter.take = Infinity
    this.filter.order = { "id": "DESC" }
    this.getEntreprise();
  }
  getQuotation() {
    this.serviceUser.getUser(this.filter).subscribe(
      data => {
        this.count = data[1]
      })
  }
  getEntreprise() {
    this.userArray = []
    let filter = new FilterDto<Entreprise>();
    filter.relations = ["contactId"];
    filter.take = Infinity;
    filter.order = { "id": "DESC" }
    this.serviceUser.getEntreprise(filter).subscribe(
      data => {
        this.userArray = data[0];
        this.count = data[1];
        this.total = this.userArray.length
        if (this.total > 0) {
          this.nbr_page = Math.ceil(this.total / this.pageSize)
        }
      }
    )
  }
  ValidAccount(item) {
    let Entreprise;
    this.serviceUser.getEntreprise({ where: { "id": item.id } }).subscribe(
      data => {
        Entreprise = data[0][0];
        Entreprise.active = true;
        this.serviceUser.editEntreprise(item.id, Entreprise).subscribe(
          data => {
            this.getEntreprise();
          }
        )
      }
    )
  }
  InValidAccount(item) {
    let Entreprise;
    this.serviceUser.getEntreprise({ where: { "id": item.id } }).subscribe(
      data => {
        Entreprise = data[0][0];
        Entreprise.active = false;
        this.serviceUser.editEntreprise(item.id, Entreprise).subscribe(
          data => {
            this.getEntreprise();
          }
        )
      }
    )
  }
  addUser() {
    this.router.navigate(['/user/users/add-user']);
  }
  deleteAction(item) {
    this.showAlert = true;
    this.userObject = item
  }
  closeAction() {
    this.showAlert = false;
  }
  openFilter() {
    this.openUserFilter = true
  }
  nameUserFilter() {
    this.nameUser = true
  }
}
