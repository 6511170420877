import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ClrDatagridStateInterface } from '@clr/angular';
import { FilterDto } from '../filter.dto';
import { FilterService } from '../shared/filter.service';
import { Retenue } from './retenue';
import { RetenueService } from './retenue.service';

@Component({
  selector: 'app-retenue',
  templateUrl: './retenue.component.html',
  styleUrls: ['./retenue.component.css']
})
export class RetenueComponent implements OnInit {

  retenues: Retenue[] = []
  count: number = 0
  filter: FilterDto = new FilterDto()
  showDeleteAlert: boolean = false
  retenueObject: Retenue

  constructor(private router: Router, private retenueService: RetenueService,
    private filterService: FilterService) { }

  ngOnInit(): void {
  }

  refresh(state: ClrDatagridStateInterface) {
    this.filter = this.filterService.bind(state);
    this.filter.take = 10
    this.filter.order = { "id": "DESC" }
    this.filter.where = { "active": true }
    this.getRetenues(this.filter);
  }
  getRetenues(filter: FilterDto) {
    this.retenueService.getRetenues(filter).subscribe(
      data => {
        this.retenues = data[0]
        this.count = data[1]
      }
    )
  }
  deleteAction(item: Retenue) {
    this.retenueObject = item
    this.showDeleteAlert = true
  }
  addRetenue() {
    this.router.navigate(["/retenue/retenues/retenue-add"])
  }

}
