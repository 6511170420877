import { Component, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { Tax } from '../tax';
import { TaxService } from '../tax.service';

@Component({
  selector: 'app-tax-add',
  templateUrl: './tax-add.component.html',
  styleUrls: ['./tax-add.component.css']
})
export class TaxAddComponent implements OnInit {

  showAlert: boolean;
  creerNouveau: boolean = false;

  constructor(private taxService: TaxService) { }
  ngOnInit(): void {

  }

  submit(tax: Tax, form: NgForm) {
    this.taxService.addTax(tax).subscribe(
      data => {
        this.showAlert = true;
        if (this.creerNouveau == true) {
          this.resetForm(form);
        } else {
          this.creerNouveau = true;
        }
      })
  }
  resetForm(form: NgForm) {
    this.creerNouveau = false;
    form.resetForm();
    this.showAlert = false;
  }
}
