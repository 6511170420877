<section>
  <div class="clr-row">
    <div class="clr-col-sm-12 clr-col-md-12">
      <div class="flex no-margin-top">
        <h1>0.2 Changelog </h1>
        <p class="beta">BETA</p>
      </div>
      <hr>
      <div class="clr-row">
        <div class="clr-col-12">
          <div class="card center">
            <div class="clr-row">
              <div class="clr-col-12">
                <br>
                <img src="assets/green.png" width="175px" height="60px">
              </div>
              <div class="clr-col-12">
                <br>
                &copy;2021
                <a href="https://iotech.tn/" target="_blank">
                  <img src="assets/logo_iotech.png" width="80" height="17">
                </a>
                <br>
              </div>
              <br>
            </div>
          </div>
        </div>
        <div class="clr-col-12 ">
          <div class="card">
            <div class="card-header center">
              <b>Date de mise à jour le 09 Avril 2021</b>
            </div>
            <div class="card-block">
              <div class="card-title">
                <div class="clr-row">
                  <div class="clr-col-6 center">
                    <span class="center">
                      Nombre de Changement
                    </span>
                  </div>
                  <div class="clr-col-6 center">
                    <span class="center">
                      Nombre de bugs
                    </span>
                  </div>
                </div>
              </div>
              <div class="card-text">
                <div class="clr-row">
                  <div class="clr-col-6 center">
                    <span class="card-body ">13 Changements</span>
                  </div>
                  <div class="clr-col-6 center">
                    <span class="card-body">2 Bugs fixés</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div>
        <h2>Changements</h2>
        <h4>
          <b>Module Vente</b>
        </h4>
        <li>Validation de commande
        </li>
        <li>Ajout du tableau des restes dans l'étape de produit  - Ajout commande </li>
        <li>Ajout des détails du client dans l'ajout de commande</li>
        <br>
        <div class="video-responsive">
          <iframe src="https://player.vimeo.com/video/534525732" width="640" height="564" frameborder="0" allow="autoplay; fullscreen" allowfullscreen></iframe>
        </div>
        <br>
        <li>Ajout rapide des clients dans l'ajout d'order </li>
        <br>
        <div class="video-responsive">
          <iframe src="https://player.vimeo.com/video/534525208" width="640" height="564" frameborder="0" allow="autoplay; fullscreen" allowfullscreen></iframe>
        </div>
        <br>
        <h4>
          <b>Module Finance</b>
        </h4>
        <li>Ajout du button creer nouveau avec l'initialisation de stepper </li>
        <li>Optimisation des scénarios d'ajout et modification des transactions (contrôle des champs)</li>
        <h4>
          <b>Module contact</b>
        </h4>
        <li>Ajout du button creer nouveau avec l'initialisation de stepper </li>
        <li>Optimisation des scénarios d'ajout et modification (contrôle des champs) dans les component Client , Fournisseur , Banque</li>
        <li>Ajout du champs ville pour le client  avec les filtrages  nécessaires</li>
        <div class="video-responsive">
          <iframe src="https://player.vimeo.com/video/534808544" width="640" height="564" frameborder="0" allow="autoplay; fullscreen" allowfullscreen></iframe>
        </div>

        <h4>
          <b>Module Paramétrage</b>
        </h4>
        <li>Ajout du button creer nouveau avec l'initialisation de stepper </li>
        <li>Optimisation des scénarios d'ajout et modification (contrôle des champs) dans les component Types , configurations , Présentation , GesTion des Utilisateurs</li>
        <li>Ajout des contraintes de sécurité lors de réinitialisations de mots de passe </li>
        <h4>
          <b>Module Stock</b>
        </h4>
        <li>Ajout d'un component pour les  Bons de Livraison et leur details  </li>
        <div class="video-responsive">
          <iframe src="https://player.vimeo.com/video/534493745" width="640" height="564" frameborder="0" allow="autoplay; fullscreen" allowfullscreen></iframe> 
        </div>
        <h2>Bugs</h2>
        <li>Correction de mode mobile pour le présentation du societé</li>
        <li>Correction de calcul totale commande on addition de remise globale</li>
      </div>
    </div>
  </div>
  <br>
  <ul class="clr-timeline clr-timeline-vertical">
    <li class="clr-timeline-step">
      <div class="clr-timeline-step-header">02 Avril 2021</div>
      <clr-icon shape="success-standard" aria-label="Completed"></clr-icon>
      <div class="clr-timeline-step-body clr-col-10">
        <span class="clr-timeline-step-title">0.1 Changelog</span>
        <div class="card clr-col-12">
          <div class="card-block">
            <div class="card-title">
              <div class="clr-row">
                <div class="clr-col-6 center">
                  <span>
                    <b>Nombre de Changement</b>
                  </span>
                </div>
                <div class="clr-col-6 center">
                  <span>
                    <b>Nombre de bugs</b>
                  </span>
                </div>
              </div>
            </div>
            <div class="card-text">
              <div class="clr-row">
                <div class="clr-col-6 center">
                  <span class="card-body">7 Changements</span>
                </div>
                <div class="clr-col-6 center">
                  <span class="card-body">1 Bugs fixés</span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div>
    <h2>Changements</h2>
    <h4><b>Module Vente</b></h4>
    <br>
    <li>Transaformation de devis vers une commande ( commandé un devis )</li>
    <li>Ajout de component Transaction </li>
    <li>Ajout du button creer nouveau avec l'initialisation de stepper </li>
    <li>Ajout du carte details  de produit dans l'ajout du commande (étape produit)</li>
    <br>
    <h4><b>Module Contact</b></h4>
    <li>Ajout du charte graphique d'achat par catégorie </li>
    <li>Ajout du Tab Commande dans le detail du client </li>
    <li>Almélioration du tabs Detail client en mode Mobile </li>
    <h2>Bugs</h2>
    <li>Correction de Maquette Component details client en Mode mobile</li>
    </div>
    </div>
    </li>
    <li class="clr-timeline-step">
      <div class="clr-timeline-step-header">26 Mars 2021</div>
      <clr-icon shape="success-standard" aria-label="Completed"></clr-icon>
      <div class="clr-timeline-step-body clr-col-10">
        <span class="clr-timeline-step-title">0.1 Changelog</span>
        <div class="card clr-col-12">
          <div class="card-block">
            <div class="card-title">
              <div class="clr-row">
                <div class="clr-col-6 center">
                  <span>
                    <b>Nombre de Changement</b>
                  </span>
                </div>
                <div class="clr-col-6 center">
                  <span>
                    <b>Nombre de bugs</b>
                  </span>
                </div>
              </div>
            </div>
            <div class="card-text">
              <div class="clr-row">
                <div class="clr-col-6 center">
                  <span class="card-body">5 Changements</span>
                </div>
                <div class="clr-col-6 center">
                  <span class="card-body">1 Bugs fixés</span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div>
    <h2>Changements</h2>
    <h4><b>Module Vente</b></h4>
    <br>
    <li>Impression du bon de commande</li>
    <br>
    <img  class="responsive-img" src="https://res.cloudinary.com/http-next-ioc-tn/image/upload/v1617978703/commande_shfqqd.png">
    <br>
    <br>
    <li>Impression de devis</li>
    <img  class="responsive-img" src="https://res.cloudinary.com/http-next-ioc-tn/image/upload/v1617984860/impression_devis_crcqzk.png">

    <li>Impression d'une livraison</li>
    <img  class="responsive-img" src="https://res.cloudinary.com/http-next-ioc-tn/image/upload/v1617987320/impression_de_livraison_adwzn1.png">

    <li>Envoi de mail aprés l'ajout d'une commande</li>
    <br>
    <img  class="responsive-img" src="    https://res.cloudinary.com/http-next-ioc-tn/image/upload/v1617978084/EmailCmd_uilwg8.png">
    <br>
    <li>Envoi de mail aprés l'ajout d'un devis</li>
    <br>
    <img  class="responsive-img" src="https://res.cloudinary.com/http-next-ioc-tn/image/upload/v1617978272/EmailDevisAdd_sk6s8d.png">
    <br>
    <h2>Bugs</h2>
    <li>Correction de Maquette Component Achat en Mode mobile</li>
    </div>
    </div>
    </li>
  </ul>
</section>