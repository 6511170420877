import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ClarityModule } from '@clr/angular';
import { LoginComponent } from './login/login.component';
import { UserManagementComponent } from './user-management/user-management.component';
import { AddUserComponent } from './user-management/add-user/add-user.component';
import { DeleteUserComponent } from './user-management/delete-user/delete-user.component';
import { DetailUserComponent } from './user-management/detail-user/detail-user.component';
import { EditUserComponent } from './user-management/edit-user/edit-user.component';
import { UserRoutingModule } from './user-routing.module';
import { SharedModule } from '../shared/shared.module';
import '@cds/core/file/register.js';
import { CdsModule } from '@cds/angular';
import { SignInComponent } from './sign-in/sign-in.component';
import { ResetPasswordComponent } from './reset-password/reset-password.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { NgbPaginationModule } from '@ng-bootstrap/ng-bootstrap';




@NgModule({
  declarations: [LoginComponent, UserManagementComponent, AddUserComponent, DeleteUserComponent, DetailUserComponent, EditUserComponent, SignInComponent, ResetPasswordComponent],
  imports: [
    CommonModule,
    FormsModule,
    ClarityModule,
    UserRoutingModule,
    SharedModule,
    CdsModule,
    ReactiveFormsModule,
    // NgbModule,
    NgbPaginationModule
  ],
  exports: [LoginComponent, SignInComponent]
})
export class UserModule { }
