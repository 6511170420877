import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { FilterDto } from 'src/app/filter.dto';
import { Timbre } from '../timbre';
import { TimbreService } from '../timbre-service.service';

@Component({
  selector: 'app-timbre-detail',
  templateUrl: './timbre-detail.component.html',
  styleUrls: ['./timbre-detail.component.css']
})
export class TimbreDetailComponent implements OnInit {

  constructor(
    private timbreService: TimbreService,
    private ActivatedRoute: ActivatedRoute
  ) { }
  id_timbre: string
  timbre: Timbre = new Timbre();
  showAlert: boolean
  ngOnInit(): void {
    this.ActivatedRoute.paramMap.subscribe(
      data => {
        this.id_timbre = data.get('id');
      }
    )
    this.getTimbre(this.id_timbre)
  }
  getTimbre(id: string) {
    let filter = new FilterDto<Timbre>();
    filter.where = { "id": this.id_timbre }
    this.timbreService.getTimbres(filter).subscribe(
      data => {
        this.timbre = data[0][0];
      }
    )
  }
  deleteAction() {
    this.showAlert = true;
  }

  closeAction() {
    this.showAlert = false;
  }

}
