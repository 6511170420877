import { Component, OnInit } from '@angular/core';
import { FilterDto } from '../filter.dto';
import { partner } from './partner';
import { PartnerService } from './partner.service';

@Component({
  selector: 'app-partner',
  templateUrl: './partner.component.html',
  styleUrls: ['./partner.component.css']
})
export class PartnerComponent implements OnInit {

  partners: partner[] = []
  showDetail: boolean = false
  partnerId: number
  showDelete: boolean = false
  constructor(private partnerService: PartnerService) { }

  ngOnInit(): void {
    this.getAllPartners();
  }
  detailAction(id: number) {
    this.partnerId = id
    this.showDetail = true
  }
  deleteAction(id: number) {
    this.partnerId = id
    this.showDelete = true
  }
  closeAction() {
    this.getAllPartners();
    this.showDelete = false
  }

  getAllPartners() {
    let filter = new FilterDto()
    filter.relations = ["abonnementId"]
    filter.where = { "active": true }
    this.partnerService.getAllPartners(filter).subscribe(
      data => {
        this.partners = data[0]
      }
    )
  }

}
