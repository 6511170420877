export class WhereFindOperator<OBJ> {
  public key?: Condition<OBJ>

}
export declare type FindOperatorType = "not" | "lessThan" | "lessThanOrEqual" | "moreThan" | "moreThanOrEqual" | "equal" | "between" | "in" | "any" | "isNull" | "ilike" | "like" | "raw";
export declare type Condition<MT> = {
  [P in keyof MT]?: {
    type?: FindOperatorType,
    value?: string | Between | number | []
  } | any;
};
export declare type Between = {
  from?: number,
  to?: number
};

export function formatDate(date: any) {
  const inputedDate = new Date(date)
  inputedDate.setDate(inputedDate.getDate())
  let month = '' + (inputedDate.getMonth() + 1),
    day = '' + (inputedDate.getDate()),
    year = inputedDate.getFullYear();
  if (month.length < 2)
    month = '0' + month;
  if (day.length < 2)
    day = '0' + day;
  return [year, month, day].join('-');
}