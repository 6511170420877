<div class="login-wrapper">
  <form class="login">
    <section class="title">
      <h5 class="hint">
        Modifier votre mot de passe <b class="text-primary">Gelivro</b>
      </h5>
    </section>
    <div class="login-group">
      <br>
      <form>
        <cds-alert-group type="banner" status="success" *ngIf="alertSuccess">
          <cds-alert closable>
            Votre mot de passe a été modifié avec succès !
          </cds-alert>
        </cds-alert-group>
        <cds-alert-group type="banner" status="danger" *ngIf="alertError">
          <cds-alert closable>
            Échec de la modification du votre mot de passe !
          </cds-alert>
        </cds-alert-group>
        <br>
        <clr-password-container>
          <label class="clr-sr-only">Mot de passe</label>
          <input type="password" name="password" clrPassword placeholder="Votre mot de passe"
            [(ngModel)]="user.password" required />
        </clr-password-container>
        <clr-password-container>
          <label class="clr-sr-only">Retapez le mot de passe</label>
          <input type="password" name="repassword" clrPassword placeholder="Retaper votre mot de passe"
            [(ngModel)]="user.repassword" required />
        </clr-password-container>
        <h6 *ngIf="user.password != user.repassword && user.repassword!=undefined && !alertSuccess && !alertError "
          class="hint text-error text-left">les deux mot de passe ne sont pas identiques ! </h6>
        <h6 *ngIf="user.password == user.repassword && user.repassword!=undefined && !alertSuccess && !alertError "
          class="hint text-success text-left">Les deux mot de passe sont identiques ! </h6>
      </form>
      <button type="submit" class="btn btn-primary" (click)="changePwd()" *ngIf="!alertSuccess">Modifier</button>
      <button type="submit" class="btn btn-primary" routerLink="/user/login" *ngIf="alertSuccess">Retour vers page
        Login</button>
      <p routerLink="/user/login" class="text-primary text-center cursor-pointer" *ngIf=" !alertSuccess">Retour vers
        page Login</p>
    </div>
  </form>
</div>