import { Component, Input, OnChanges} from '@angular/core';
import { ClrDatagridFilterInterface } from '@clr/angular';
import { Subject } from 'rxjs';
import { FilterDto } from 'src/app/filter.dto';
import { OperationType, TransactionType } from 'src/app/setting/type/type';
// import { StockService } from 'src/app/stock/stock.service';
import { SettingService } from '../../setting/setting.service';

@Component({
  selector: 'app-checkbox-list-filter',
  templateUrl: './checkbox-list-filter.component.html',
  styleUrls: ['./checkbox-list-filter.component.css']
})
export class CheckboxListFilterComponent implements ClrDatagridFilterInterface<{ key: string, value: string },OnChanges> {

  public static instanceof(obj: any) {
    return obj.hasOwnProperty('filterParamName') && obj.hasOwnProperty('items') && obj.hasOwnProperty('selectedItems');
  }

  @Input() public filterParamName: string;
  @Input() public items: Array<{ key: string, value: string, checked: boolean }>;
  @Input() transctionFilter: boolean;
  @Input() filterClicked: boolean;
  @Input() filterEtat:boolean;
  
  public selectedItems: Array<{ key: string, value: string }> = [];
  public changes = new Subject<any>();
  public get state() {
    return this;
    
  }
  transactionTypeFilter: TransactionType[];
  operationTypeFilter: OperationType[];
  filter: FilterDto = new FilterDto();
  where={}



  constructor( private settingservice: SettingService,
    // private stockService:StockService
    ) {}

  ngOnChanges(){
  if(this.transctionFilter){
      this.settingservice.getTransactionTypes(this.filter).subscribe(
    res => {
      this.transactionTypeFilter = res[0]
    }
  )

  }
  // if(this.filterClicked){
  //   this.filter.where={active:true}
  //   this.stockService.getOperationType(this.filter).subscribe(
  //     res => {
  //       this.operationTypeFilter = res[0]
  //     }
  //   )
  // }

}
  onItemChanged(item) {
    if (!item.checked) {
      item.checked = true;
      this.selectedItems.push(item);
      let property=this.filterParamName
      this.where[property]=item[property]
    } else {
      item.checked = false;
      let property=this.filterParamName
      this.where[property]=item[property]
      let index = this.selectedItems.indexOf(item);
      if (index >= 0) {
        this.selectedItems.splice(index, 1);
      }
    }
    this.changes.next(true);
  }


  accepts(item): boolean {
    return true;
  }

  isActive(): boolean {
    return this.selectedItems != null && this.selectedItems.length > 0;
  }
}
