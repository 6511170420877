import { EtatCommandeComponent } from './etat-commande/etat-commande.component';
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { InformationComponent } from './core/information/information.component';
import { SearchComponent } from './core/search/search.component';
import { DashboardComponent } from './dashboard/dashboard/dashboard.component';
import { AddDeviseComponent } from './devise/add-devise/add-devise.component';
import { DetailDeviseComponent } from './devise/detail-devise/detail-devise.component';
import { DeviseComponent } from './devise/devise.component';
import { EditDeviseComponent } from './devise/edit-devise/edit-devise.component';
import { PartnerAddComponent } from './partner/partner-add/partner-add.component';
import { PartnerEditComponent } from './partner/partner-edit/partner-edit.component';
import { PartnerComponent } from './partner/partner.component';
import { RetenueAddComponent } from './retenue/retenue-add/retenue-add.component';
import { RetenueDetailComponent } from './retenue/retenue-detail/retenue-detail.component';
import { RetenueEditComponent } from './retenue/retenue-edit/retenue-edit.component';
import { RetenueComponent } from './retenue/retenue.component';
import { TaxAddComponent } from './tax/tax-add/tax-add.component';
import { TaxEditComponent } from './tax/tax-edit/tax-edit.component';
import { TaxComponent } from './tax/tax.component';
import { TimbreAddComponent } from './timbre/timbre-add/timbre-add.component';
import { TimbreDetailComponent } from './timbre/timbre-detail/timbre-detail.component';
import { TimbreEditComponent } from './timbre/timbre-edit/timbre-edit.component';
import { TimbreComponent } from './timbre/timbre.component';
import { LoginComponent } from './user/login/login.component';
import { ResetPasswordComponent } from './user/reset-password/reset-password.component';
import { SignInComponent } from './user/sign-in/sign-in.component';
import { NewsComponent } from './news/news.component';
import { NewsAddComponent } from './news/news-add/news-add.component';
import { NewsEditComponent } from './news/news-edit/news-edit.component';
import { UserDemandsComponent } from './user-demands/user-demands.component';
import { EtatCommandeAddComponent } from './etat-commande/etat-commande-add/etat-commande-add.component';
import { EtatCommandeEditComponent } from './etat-commande/etat-commande-edit/etat-commande-edit.component';


const routes: Routes = [
  { path: 'user/login', component: LoginComponent },
  { path: 'user/reset-password', component: ResetPasswordComponent },
  {
    path: 'user',
    loadChildren: () => import('./user/user.module').then(m => m.UserModule)
  },
  {
    path: 'abonnement',
    loadChildren: () => import('./abonnement/abonnement.module').then(m => m.AbonnementModule)
  },
  {
    path: 'devise/devises', component: DeviseComponent
  },
  {
    path: 'devise/devises/add-devise', component: AddDeviseComponent
  },
  {
    path: 'devise/devises/detail-devise/:id', component: DetailDeviseComponent
  },
  {
    path: 'devise/devises/edit-devise/:id', component: EditDeviseComponent
  },
  { path: 'timbre/timbres', component: TimbreComponent },
  { path: 'timbre/timbres/timbre-add', component: TimbreAddComponent },
  { path: 'timbre/timbres/timbre-edit/:id', component: TimbreEditComponent },
  { path: 'timbre/timbres/timbre-detail/:id', component: TimbreDetailComponent },
  { path: 'retenue/retenues', component: RetenueComponent },
  { path: 'retenue/retenues/retenue-add', component: RetenueAddComponent },
  { path: 'retenue/retenues/retenue-edit/:id', component: RetenueEditComponent },
  { path: 'retenue/retenues/retenue-detail/:id', component: RetenueDetailComponent },
  { path: 'tax/taxs', component: TaxComponent },
  { path: "tax/taxs/tax-add", component: TaxAddComponent },
  { path: "tax/taxs/tax-edit/:id", component: TaxEditComponent },
  { path: "partner/partners", component: PartnerComponent },
  { path: "partner/partners/partner-add", component: PartnerAddComponent },
  { path: "partner/partners/partner-edit/:id", component: PartnerEditComponent },
  { path: "demandes/messages" ,component:UserDemandsComponent},
  { path:"demandes/contacts",component:UserDemandsComponent},
  {
    path: "news/news", component: NewsComponent
  },
  {
    path: "news/news/news-add", component: NewsAddComponent
  },
  {
    path: "news/news/news-edit/:id", component: NewsEditComponent
  },
  {
    path: 'sing-in', component: SignInComponent
  },
  {
    path: 'dashboard', component: DashboardComponent
  },
  {
    path: 'search', component: SearchComponent
  },
  // {
  //   path: 'activites', component: ActivitesComponent
  // },

  {
    path: 'setting',
    loadChildren: () => import('./setting/setting.module').then(m => m.SettingModule)
  },
  {
    path: 'informations', component: InformationComponent
  },

  {
    path: 'etat-commande', component: EtatCommandeComponent
  },
  {
    path: 'etat-commande/add', component: EtatCommandeAddComponent
  },
  {
    path: 'etat-commande/edit/:id', component: EtatCommandeEditComponent
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
