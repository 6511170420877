import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ClrDatagridStateInterface } from '@clr/angular';
import { FilterDto } from '../filter.dto';
import { FilterService } from '../shared/filter.service';
import { Devise } from './devise';
import { DeviseServiceService } from './devise-service.service';

@Component({
  selector: 'app-devise',
  templateUrl: './devise.component.html',
  styleUrls: ['./devise.component.css']
})
export class DeviseComponent implements OnInit {

  constructor(private deviseService: DeviseServiceService,
    private router: Router
    , private filterService: FilterService,
  ) { }

  devises: Devise[];
  count: number
  filter: FilterDto<any> = new FilterDto<any>();
  alertDelete: boolean
  deviseObject: Devise = new Devise();
  ngOnInit(): void {

  }
  getDevises(filter: FilterDto<Devise>) {
    filter.where = { "active": true }
    this.deviseService.getDevises(filter).subscribe(
      data => {
        this.devises = data[0];
        this.count = data[1];
      }
    )
  }
  deleteAction(item) {
    this.alertDelete = true
    this.deviseObject = item
  }
  addDevise() {
    this.router.navigate(['/devise/devises/add-devise']);
  }
  closeAction() {
    this.alertDelete = false
  }
  refresh(state: ClrDatagridStateInterface) {
    this.filter = this.filterService.bind(state);
    this.filter.take = 10
    this.filter.order = { "id": "DESC" }
    this.filter.where = { "active": true }
    this.getDevises(this.filter);
  }
}
