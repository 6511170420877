<div class="clr-row clr-align-items-end ">
  <div class="clr-col-9">
    <clr-date-container>
      <label>De</label>
      <input type="date" [(clrDate)]="startDate" (clrDateChange)="startDateChanged($event)">
    </clr-date-container>
  </div>
  <div class="clr-col-3">
    <div>
      <button type="button" class="btn btn-icon btn-sm btn-warning resetDate"
        title="réinitialiser le date de début" (click)="resetStartDate()">
        <clr-icon shape="times"></clr-icon>
      </button>
    </div>
  </div>
</div>
<div class="clr-row clr-align-items-end ">
  <div class="clr-col-9">
    <clr-date-container>
      <label>Jusqu'à</label>
      <input type="date" [(clrDate)]="endDate" (clrDateChange)="endDateChanged($event)">
    </clr-date-container>
  </div>
  <div class="clr-col-3">
    <div>
      <button type="button" class="btn btn-icon btn-sm btn-warning resetDate"
        title="réinitialiser le date de fin" (click)="resetEndDate()">
        <clr-icon shape="times"></clr-icon>
      </button>
    </div>
  </div>
</div>