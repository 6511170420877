<div *ngIf="show">
    <a clrVerticalNavLink routerLink="user/users" routerLinkActive="active">
        <clr-icon shape="users" clrVerticalNavIcon></clr-icon>
        <label>Gestion des utilisateurs</label>
    </a>
    <a clrVerticalNavLink routerLink="partner/partners" routerLinkActive="active">
        <clr-icon shape="users" clrVerticalNavIcon></clr-icon>
        <label>Gestion des partenaires </label>
    </a>
    <a clrVerticalNavLink routerLink="abonnement/abonnements" routerLinkActive="active">
        <clr-icon shape="cursor-hand-click" clrVerticalNavIcon></clr-icon>
        <label>Gestion des abonnements</label>
    </a> 
    <a clrVerticalNavLink routerLink="devise/devises" routerLinkActive="active">
        <clr-icon shape="dollar" clrVerticalNavIcon></clr-icon>
        <label>Gestion des devises</label>
    </a>
    <a clrVerticalNavLink routerLink="setting/type" routerLinkActive="active">
        <clr-icon shape="cog" clrVerticalNavIcon></clr-icon>
        <label>Configuration des Types</label>
    </a>
    <clr-vertical-nav-group>
        <clr-icon shape="cog" clrVerticalNavIcon></clr-icon>
        <label>Config des valeurs</label>
        <clr-vertical-nav-group-children>
            <a clrVerticalNavLink routerLink="timbre/timbres" routerLinkActive="active">
                <label>Gestion des Timbres</label>
            </a>
            <a clrVerticalNavLink routerLink="retenue/retenues" routerLinkActive="active">
                <label>Gestion retenues à la source </label>
            </a>
            <a clrVerticalNavLink routerLink="tax/taxs" routerLinkActive="active">
                <label>
                    Gestion des taxs
                </label>
            </a>
        </clr-vertical-nav-group-children>
    </clr-vertical-nav-group>
    <clr-vertical-nav-group>
        <clr-icon shape="info-standard" clrVerticalNavIcon></clr-icon>
        <label>Demandes des utilisateurs </label>
        <clr-vertical-nav-group-children>
            <a clrVerticalNavLink routerLink="demandes/messages" routerLinkActive="active">
                <label>Messages de feedback</label>
            </a>
            <a clrVerticalNavLink routerLink="demandes/contacts" routerLinkActive="active">
                <label>Demandes de contact  </label>
            </a>
        </clr-vertical-nav-group-children>
    </clr-vertical-nav-group>
    <a clrVerticalNavLink routerLink="news/news" routerLinkActive="active">
        <clr-icon shape="cog" clrVerticalNavIcon></clr-icon>
        <label>Gestion des infos des nouveautés</label>
    </a>
    <a clrVerticalNavLink routerLink="etat-commande" routerLinkActive="active">
        <clr-icon shape="cog" clrVerticalNavIcon></clr-icon>
        <label>Etat Commandes</label>
    </a>
</div>