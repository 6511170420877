<section>
  <div class="alert alert-success" role="alert" *ngIf="success">
    <div class="alert-items">
      <div class="alert-item static">
        <div class="alert-icon-wrapper">
          <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-check-circle"
            viewBox="0 0 16 16">
            <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
            <path
              d="M10.97 4.97a.235.235 0 0 0-.02.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-1.071-1.05z" />
          </svg>
        </div>
        <span class="alert-text">
          {{successMsg}}
        </span>
      </div>
    </div>
  </div>
  <div class="alert alert-danger" role="alert" *ngIf="!isMatchingPwd || noImage || !passwordValid || formatInvalid">
    <div class="alert-items">
      <div class="alert-item static">
        <div class="alert-icon-wrapper">
          <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
            class="bi bi-exclamation-circle" viewBox="0 0 16 16">
            <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
            <path
              d="M7.002 11a1 1 0 1 1 2 0 1 1 0 0 1-2 0zM7.1 4.995a.905.905 0 1 1 1.8 0l-.35 3.507a.552.552 0 0 1-1.1 0L7.1 4.995z" />
          </svg>
        </div>
        <span class="alert-text">
          {{erreurMsg}}
        </span>
      </div>
    </div>
  </div>
  <h3 *ngIf="!canResetPwd">Modification d'utilisateur</h3>
  <form clrForm *ngIf="stopLoading && !canResetPwd">
    <clr-input-container>
      <label>Nom *</label>
      <input clrInput type="text" [(ngModel)]="userObject.name" name="name" />
    </clr-input-container>

    <clr-input-container>
      <label>Tel*</label>
      <input clrInput [(ngModel)]="contact.tel" clrInput type="number" name="tel"
        pattern="(([+]?[0-3]{2}?[0-9]{8})||([0-9]{8}))" required />
      <clr-control-error *clrIfError="'required'">Ce champ est obligatoire </clr-control-error>
      <clr-control-error *clrIfError="'pattern'"> Numéro de téléphone est incorrect</clr-control-error>
    </clr-input-container>

    <!-- <clr-input-container>
      <label>Fax</label>
      <input clrInput [(ngModel)]="contact.fax" clrInput type="number" name="fax"
        pattern="(([+]?[0-3]{2}?[0-9]{8})||([0-9]{8}))" required />
    </clr-input-container> -->

    <clr-input-container>
      <label>Email*</label>
      <input clrInput type="text" class="form-control" name="email" [(ngModel)]="userObject.email" email />
      <clr-control-error>Adresse mail non valide</clr-control-error>
    </clr-input-container>


    <clr-radio-container clrInline>
      <label>Autorisation*</label>
      <clr-radio-wrapper>
        <input type="radio" clrRadio name="role" required value="user" [(ngModel)]="userObject.role" />
        <label>Utilisateur</label>
      </clr-radio-wrapper>
      <clr-radio-wrapper>
        <input type="radio" clrRadio name="role" required value="writer" [(ngModel)]="userObject.role" />
        <label>Traiteur</label>
      </clr-radio-wrapper>
      <clr-radio-wrapper>
        <input type="radio" clrRadio name="role" required value="admin" [(ngModel)]="userObject.role" />
        <label>Admin</label>
      </clr-radio-wrapper>
      <clr-control-error>Ce champ est obligatoire!</clr-control-error>
    </clr-radio-container>

    <clr-input-container>
      <label>Image:</label>
    </clr-input-container>
    <div class="clr-row clr-align-items-center">
      <!-- <span class="spinner spinner-sm spinner-pos" *ngIf="!userObject.logo">
        <strong>pas d'image </strong>
      </span> -->
      <!-- <span  *ngIf="!userObject.logo">
        <strong> pas d'image</strong>
      </span> -->
      <div class="clr-col-7 clr-col-md-4  clr-offset-sm-2 " *ngIf="userObject.logo">
        <div class="img">
          <div class="card">
            <div class="card-img container">
              <img [src]="userObject.logo">
            </div>
          </div>
          <br>
        </div>
        <div>
          <cds-file>
            <label></label>
            <input (click)="fileInput.click()" />
          </cds-file>
        </div>
        <div class="clr-row clr-justify-content-rigth">
          <input hidden type="file" #fileInput (change)="picked($event)">
        </div>
      </div>
    </div>



  </form>
  <form clrForm *ngIf="canResetPwd">
    <clr-password-container>
      <label> Ancien Mot de passe</label>
      <input clrPassword placeholder="Ancien Mot de passe" type="Password" [(ngModel)]="userObject.oldPassword"
        name="password" minlength="4" />
      <clr-control-helper>minimum 4 caractères</clr-control-helper>
      <clr-control-error *clrIfError="'required'">Ce champ est obligatoire!</clr-control-error>
      <clr-control-error *clrIfError="'minlength'">minimum 4 caractères!</clr-control-error>
    </clr-password-container>

    <clr-password-container>
      <label>Nouvelle Mot de passe</label>
      <input clrPassword placeholder="nouvelle mot de passe" type="Password" [(ngModel)]="userObject.password"
        name="password" minlength="4" />
      <clr-control-helper>minimum 4 caractères</clr-control-helper>
      <clr-control-error *clrIfError="'required'">Ce champ est obligatoire!</clr-control-error>
      <clr-control-error *clrIfError="'minlength'">minimum 4 caractères!</clr-control-error>
    </clr-password-container>

    <clr-password-container>
      <label>Confimer Mot de passe</label>
      <input clrPassword placeholder="nouvelle mot de passe" type="Password" [(ngModel)]="userObject.repassword"
        name="password" minlength="4" />
      <clr-control-helper>minimum 4 caractères</clr-control-helper>
      <clr-control-error *clrIfError="'required'">Ce champ est obligatoire!</clr-control-error>
      <clr-control-error *clrIfError="'minlength'">minimum 4 caractères!</clr-control-error>
    </clr-password-container>
    <br>
    <div class="btn-group btn-sm btn-outline-primary reset-group">
      <button type="button" class="btn btn-sm clr-offset-4" (click)="annulerReset()"><small>Retour</small></button>
      <button type="button" class="btn btn-sm clr-offset-4" (click)="changePwd()"
        *ngIf="userObject.oldPassword && userObject.password && userObject.repassword">
        <small>Changer mot de passe</small></button>
    </div>
  </form>
  <br>
  <br>
  <div class="btn-group btn-sm btn-outline-primary" *ngIf="!canResetPwd">
    <button class="btn" routerLink="../../">
      <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" fill="currentColor"
        class="bi bi-arrow-counterclockwise" viewBox="-1 -2 16 16">
        <path fill-rule="evenodd" d="M8 3a5 5 0 1 1-4.546 2.914.5.5 0 0 0-.908-.417A6 6 0 1 0 8 2v1z" />
        <path d="M8 4.466V.534a.25.25 0 0 0-.41-.192L5.23 2.308a.25.25 0 0 0 0 .384l2.36 1.966A.25.25 0 0 0 8 4.466z" />
      </svg> Retour
    </button>
    <button class="btn" (click)="submitAction()"
      *ngIf="userObject.name && userObject.role && userObject.email  && contact.tel">
      <svg id="btn_plus" xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
        class="bi bi-plus" viewBox="-1 -4 16 16">
        <path
          d="M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4z" />
      </svg> Modifier
    </button>
    <button type="button" class="btn btn-sm clr-offset-4" [clrLoading]="validateBtnState"
      (click)="openResetPwd()"><small>Changer mot de passe</small></button>
  </div>
</section>