import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { UserDemandsService } from './service/user-demands.service';
import { FeedBack } from './feedback';
import { FilterDto } from '../filter.dto';

@Component({
  selector: 'app-user-demands',
  templateUrl: './user-demands.component.html',
  styleUrls: ['./user-demands.component.css']
})
export class UserDemandsComponent implements OnInit {

  messages : boolean
  feedbacks : FeedBack[]=[]
  tmpList : FeedBack[]=[]
  showDetail : boolean = false 
  showDelete : boolean = false 
  feedbackObject : FeedBack = new FeedBack()
  feedbackId : number

  constructor(private router : Router,private userDemandService : UserDemandsService ) { }

  ngOnInit(): void {
    this.messages = this.router.url.indexOf("messages") != -1
    this.getFeedbacks()
  }

  detailAction(item : FeedBack){
    this.showDetail = true
    this.feedbackObject = item
  }

  deleteAction(item : FeedBack){
    this.feedbackId = item.id
    this.showDelete = true 
  }

  refreshList(){
    this.showDelete = false 
    this.getFeedbacks()
  }

  getFeedbacks(){
    let filter = new FilterDto()
    filter.relations = ["entrepriseId"]
    this.userDemandService.getAllFeedbacks(filter).subscribe(
      data => {
        this.tmpList = data[0]
        if(this.messages){
          this.feedbacks = []
          this.tmpList.forEach(el=>{
            if(!el.tel){
              this.feedbacks.push(el)
            }
          })
        }else{
          this.feedbacks = []
          this.tmpList.forEach(el=>{
            if(!el.content){
              this.feedbacks.push(el)
            }
          })
        }
      }
    )
  }

  treatFeedBack(item : FeedBack){
    let model = new FeedBack()
    model.treated = true 
    this.userDemandService.updateFeedBack(item.id,model).subscribe(
      data => {
        this.getFeedbacks();
      }
    )
  }

  inTreatFeedback(item : FeedBack){
    let model = new FeedBack()
    model.treated = false 
    this.userDemandService.updateFeedBack(item.id,model).subscribe(
      data => {
        this.getFeedbacks();
      }
    )
  }

}
