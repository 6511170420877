import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { FilterDto } from 'src/app/filter.dto';
import { Devise } from '../devise';
import { DeviseServiceService } from '../devise-service.service';

@Component({
  selector: 'app-edit-devise',
  templateUrl: './edit-devise.component.html',
  styleUrls: ['./edit-devise.component.css']
})
export class EditDeviseComponent implements OnInit {

  success: boolean
  deviseObject: Devise = new Devise();
  deviseId: string
  failed: boolean
  constructor(
    private deviseService: DeviseServiceService,
    private route: ActivatedRoute
  ) { }

  ngOnInit(): void {
    this.route.paramMap.subscribe(
      data => {
        this.deviseId = data.get('id')
      }
    )
    this.getDevise(this.deviseId);
  }

  getDevise(id: string) {
    let filter = new FilterDto<Devise>();
    filter.where = { "id": id }
    this.deviseService.getDevises(filter).subscribe(
      data => {
        this.deviseObject = data[0][0];
      }
    )
  }
  submitAction() {
    this.deviseService.updateDevise(this.deviseObject.id, this.deviseObject).subscribe(
      data => {
        this.success = true
      },
      er => {
        this.failed = true;
      }
    )
  }

}
