import { Component, OnInit } from '@angular/core';
import { FilterDto } from '../filter.dto';
import { FilterService } from '../shared/filter.service';
import { Tax } from './tax';
import { TaxService } from './tax.service';

@Component({
  selector: 'app-tax',
  templateUrl: './tax.component.html',
  styleUrls: ['./tax.component.css']
})
export class TaxComponent implements OnInit {

  taxs: Tax[];
  selectedTax: Tax
  count: number;
  showAlert: boolean;
  loading: boolean;
  filter: FilterDto;
  showDetail: boolean;
  currentLang: string;
  lg = "rtl"
  x = false;
  y: string;
  id_entreprise: number
  userRole: string

  constructor(
    private taxService: TaxService, private filterService: FilterService) {
  }

  ngOnInit() {
    this.getTaxs({ where: { "active": true } })
  }
  reloadData() {
    window.location.reload();
  }

  getTaxs(filter: FilterDto<Tax>) {
    this.taxService.getTaxs(filter).subscribe(
      data => {
        this.taxs = data[0];
        this.count = data[1];
      },
      err => console.error('Observer got an error: ' + err),
      () => this.loading = false
    );
  }

  delete(tax: Tax) {
    this.selectedTax = tax
    this.showAlert = true;
  }
  detail(tax: Tax) {
    this.selectedTax = tax
    this.showDetail = true;
  }
  close() {
    this.getTaxs({ where: { "active": true } });
    this.showAlert = false;
    this.showDetail = false;
  }
}
