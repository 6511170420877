import { Component, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { Devise, PostDevise } from '../devise';
import { DeviseServiceService } from '../devise-service.service';

@Component({
  selector: 'app-add-devise',
  templateUrl: './add-devise.component.html',
  styleUrls: ['./add-devise.component.css']
})
export class AddDeviseComponent implements OnInit {

  constructor(
    private deviseService: DeviseServiceService
  ) { }
  showAlert: boolean;
  addNew: boolean
  alertError: boolean;
  success: boolean;
  devises: Devise[];

  ngOnInit(): void {
    this.deviseService.getDevises({}).subscribe(
      data => {
        this.devises = data[0];
      }
    )
  }

  submit(deviseForm: PostDevise) {
    const exist = this.devises.filter(x => x.code == deviseForm.code)
    if (exist.length > 0) {
      this.alertError = true;
    } else {
      this.deviseService.addDevise(deviseForm).subscribe(
        data => {
          this.success = true;
          this.addNew = true;
        }
      )
    }
  }
  resetForm(form: NgForm) {
    form.reset();
    this.showAlert = false;
    this.addNew = false
    this.ngOnInit();
  }
}
