<div class="login-wrapper">
  <form class="login">
    <section class="title">
    
      <h1><b>Espace Administrateur</b></h1>
      <img class="center" src="assets\inveep.png" width="250"/>
      <h5 class="hint" *ngIf="isLogin && !linkSent">
        Veuillez renseigner l'adresse e-mail que vous avez utilisée à la création de votre compte. Vous recevrez un lien temporaire pour réinitialiser votre mot de passe.
      </h5>
      <h5 class="hint" *ngIf="linkSent && isLogin">
        S'il y a un compte associé à <b class="text-primary">{{resetMail}}</b>  vous recevrez un email avec un lien pour réinitialiser votre mot de passe.
      </h5>
    </section>
    <div class="login-group">
      <div *ngIf="userId">
        <clr-icon shape="times-circle" class="edit-btn" (click)="editEmail()"></clr-icon>
        <img class="card-media-image" [src]="picture" width="50px" height="50px" />&nbsp;
        <span><b>{{user.email}}</b></span>
      </div>
      <br>
      <form *ngIf="!isLogin">
      <clr-input-container *ngIf="!userId">
        <label class="clr-sr-only">Email</label>
        <input type="text" name="email" clrInput placeholder="Inveep@inv.tn" [(ngModel)]="user.email" />
      </clr-input-container>
      <clr-password-container>
        <label class="clr-sr-only">Mot de passe</label>
        <input type="password" name="password" clrPassword placeholder="********" [(ngModel)]="user.password" />
      </clr-password-container>
      <clr-checkbox-wrapper>
        <label>Souviens-toi de moi</label>
        <input type="checkbox" name="rememberMe " clrCheckbox [(ngModel)]="user.rememberMe "
          (change)="onChange($event)" />
      </clr-checkbox-wrapper>
      <div *ngIf="show && !isLogin" class="error active"> Email ou Mot de passe invalide !</div>
    </form>
      <div *ngIf="isLogin">
        <clr-input-container *ngIf="!userId">
          <label class="clr-sr-only">Email</label>
          <input type="text" name="email" clrInput placeholder="Votre adresse mail" [(ngModel)]="resetMail"  (ngModelChange)="valuechange($event)" />
        </clr-input-container>
        <div *ngIf="mailInvalid || !resetMail && resetMail !=''  " class="error active"> Email ou Mot de passe invalide !</div>
      </div>
      <button type="submit" *ngIf="!isLogin" class="btn btn-success" (click)="login()">Connexion</button>
      <a routerLink="/user/sign-in" *ngIf="!isLogin" class="signup">S'inscrire</a>
      <button type="button" *ngIf="isLogin && !linkSent" class="btn btn-primary" (click)="submit()">Envoyer le lien</button>
      <button type="button" *ngIf="isLogin && linkSent" class="btn btn-success cursor-no-drop" >Le lien de réinitialiser a été envoyé</button>
      <br>
      <div class="row clr-justify-content-end flex">
        <div class="col-4 ">
          <span class="text-right cursor-pointer text-primary" *ngIf="!isLogin" (click)="isLogin=!isLogin">Mot de passe oubliés ?</span>
          <span class="text-right cursor-pointer text-primary" *ngIf="isLogin" (click)="isLogin=!isLogin"> Retour</span>
        </div>
      </div>
    </div>
  </form>
</div>