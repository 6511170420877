import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { FilterDto } from 'src/app/filter.dto';
import { Timbre } from '../timbre';
import { TimbreService } from '../timbre-service.service';

@Component({
  selector: 'app-timbre-edit',
  templateUrl: './timbre-edit.component.html',
  styleUrls: ['./timbre-edit.component.css']
})
export class TimbreEditComponent implements OnInit {

  constructor(
    private timberService: TimbreService, private route: ActivatedRoute
  ) { }
  success: boolean
  timberObject: Timbre = new Timbre();
  timberId: string
  failed: boolean
  ngOnInit(): void {
    this.route.paramMap.subscribe(
      data => {
        this.timberId = data.get('id')
      }
    )
    this.getTimbre(this.timberId);
  }
  getTimbre(id: string) {
    let filter = new FilterDto<Timbre>();
    filter.where = { "id": id }
    this.timberService.getTimbres(filter).subscribe(
      data => {
        this.timberObject = data[0][0];
      }
    )
  }
  submitAction() {
    this.timberService.updateTimbre(this.timberObject.id, this.timberObject).subscribe(
      data => {
        this.success = true
      },
      er => {
        this.failed = true;
      }
    )
  }

}
