<section>
  <h3><b> &nbsp;CRÉEZ VOTRE COMPTE Admin</b></h3>
  <br>
</section>
<div class="clr-row">
  <div class="clr-col-5">

  </div>
</div>
<div class="login-wrapper">
  <form clrStepper>
    <clr-stepper-panel ngModelGroup="step1">
      <clr-step-title>Utilisateur</clr-step-title>
      <clr-step-content *clrIfExpanded>
        <div class="alert alert-success" role="alert" *ngIf="showAlert && !usedMail">
          <div class="alert-items">
            <div class="alert-item static">
              <div class="alert-icon-wrapper">
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                  class="bi bi-check-circle" viewBox="0 0 16 16">
                  <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
                  <path
                    d="M10.97 4.97a.235.235 0 0 0-.02.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-1.071-1.05z" />
                </svg>
              </div>
              <span class="alert-text">
                Utilisateur a été ajouté avec succès !
              </span>
            </div>
          </div>
        </div>
        <div class="alert alert-danger" role="alert" *ngIf="usedMail">
          <div class="alert-items">
            <div class="alert-item static">
              <div class="alert-icon-wrapper">
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                  class="bi bi-check-circle" viewBox="0 0 16 16">
                  <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
                  <path
                    d="M10.97 4.97a.235.235 0 0 0-.02.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-1.071-1.05z" />
                </svg>
              </div>
              <span class="alert-text">
                {{erreurMsg}}
              </span>
            </div>
          </div>
        </div>
        <form clrForm [formGroup]="signForm">
          <clr-input-container>
            <label>Nom et prénom*</label>
            <input clrInput type="text" formControlName="name" name="name" [(ngModel)]="objectUser.name" required />
            <clr-control-error *clrIfError="'required'">Ce champ est obligatoire</clr-control-error>
          </clr-input-container>
          <clr-input-container>
            <label>Email *</label>
            <input clrInput type="text" class="form-control" formControlName="email" name="email"
              [(ngModel)]="objectUser.email" required email />
          </clr-input-container>
          <clr-password-container>
            <label>Password</label>
            <input clrPassword formControlName="password" name="password" [(ngModel)]="objectUser.password" />
            <clr-control-error *clrIfError="'required'">This password is required!</clr-control-error>
            <clr-control-error *clrIfError="'minlength'">It must be at least 4 characters!</clr-control-error>
            <clr-control-error *clrIfError="'maxlength'">It must be less than 12 characters!</clr-control-error>
          </clr-password-container>
          <clr-password-container>
            <label> Confirm Password</label>
            <input clrPassword formControlName="repassword" [(ngModel)]="repassword" />
            <clr-control-error *clrIfError="'required'">Confirm Password is required!</clr-control-error>
            <clr-control-error *clrIfError="'mismatch'">Confirm Password non valide</clr-control-error>
          </clr-password-container>
        </form>
        <br>
        <button class="btn btn-success btn-sm" type="submit" (click)="submit()"
          [disabled]="objectUser?.password != repassword">Enregistrer</button>
      </clr-step-content>
    </clr-stepper-panel>
  </form>
</div>
