import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { News } from '../news';

@Component({
  selector: 'app-news-detail',
  templateUrl: './news-detail.component.html',
  styleUrls: ['./news-detail.component.css']
})
export class NewsDetailComponent implements OnInit {

  @Output() closed = new EventEmitter<boolean>()
  @Input() newsObject: News
  constructor() { }

  ngOnInit(): void {
  }

  CloseAction() {
    this.closed.emit(true)
  }

}
