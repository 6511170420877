import { Component, OnInit } from '@angular/core';
import { News, NewsPicture } from '../news';
import { NewsService } from '../news.service';
import { PictureObject } from 'src/app/user/user';
import { Router } from '@angular/router';

@Component({
  selector: 'app-news-add',
  templateUrl: './news-add.component.html',
  styleUrls: ['./news-add.component.css']
})
export class NewsAddComponent implements OnInit {

  newsObject: News = new News()
  pictureObject: NewsPicture = new NewsPicture()
  creerNouveau: boolean = false
  showError: boolean = false
  erreurMsg: string = ""
  success: boolean = false
  constructor(private newsService: NewsService, private router: Router) { }

  ngOnInit(): void {

  }

  submit() {
    this.newsService.addNews(this.newsObject).subscribe(
      data => {
        this.pictureObject.newsId = data.id
        this.newsService.addPictures(this.pictureObject).subscribe(
          pic => {
            this.success = true
            this.creerNouveau = true
            setTimeout(() => {
              this.router.navigate(["news/news"])
            }, 3000);
          }
        )
      }
    )
  }
  resetForm() {
    this.newsObject = new News()
    this.creerNouveau = false
  }
  picked(event) {
    let fileList: FileList = event.target.files;
    if (fileList.length > 0) {
      const file: File = fileList[0];
      if (file.size > 500000) {
        this.showError = true;
        this.erreurMsg = 'Taille fichier invalide !'
        return;
      }
      this.handleInputChange(file);
    }
    else {
      this.showError = true
      this.erreurMsg = 'Aucune image sélectionnée !'
    }
  }
  deletePicture() {
    this.pictureObject.url = ""
  }

  handleInputChange(files) {
    this.showError = false
    const file = files;
    const pattern = /image-*/;
    const reader = new FileReader();
    if (!file.type.match(pattern)) {
      this.showError = true
      this.erreurMsg = "Format de fichier est invalide"
      return;
    }
    reader.onloadend = this._handleReaderLoaded.bind(this);
    reader.readAsDataURL(file);
  }

  async _handleReaderLoaded(e) {
    const picture: PictureObject = new PictureObject()
    picture.base64Data = e.target.result;
    picture.url = e.target.result;
    this.pictureObject.url = picture.url
  }
}
