import { Component, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { EtatCommande } from '../etat-commande';
import { EtatCommandeService } from '../etat-commande.service';

@Component({
  selector: 'app-etat-commande-add',
  templateUrl: './etat-commande-add.component.html',
  styleUrls: ['./etat-commande-add.component.css']
})
export class EtatCommandeAddComponent implements OnInit {

  alertSuccess: boolean
  alertError: boolean;
  addEtatCommande: boolean;

  constructor(private etatCommandeService: EtatCommandeService) { }

  ngOnInit(): void {
  }

  submit(etatCommande: EtatCommande, form: NgForm) {
    etatCommande.coefficient = +etatCommande.coefficient
    this.etatCommandeService.addEtatCommande(etatCommande)
      .subscribe(
        data => {
          this.alertSuccess = true;
          if (this.addEtatCommande == true) {
            this.addEtatCommande = false;
          } else {
            this.addEtatCommande = true;
          }
        },
        err => {
          console.error('Observer got an error: ' + err)
          this.alertError = true
        }
      )
  }

  reset(form: NgForm) {
    this.addEtatCommande = false;
    this.alertSuccess = false
    this.alertError = false
    form.resetForm();
  }
}
