import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { EtatCommande } from '../etat-commande';

@Component({
  selector: 'app-etat-commande-detail',
  templateUrl: './etat-commande-detail.component.html',
  styleUrls: ['./etat-commande-detail.component.css']
})
export class EtatCommandeDetailComponent implements OnInit {

  @Output() closed = new EventEmitter<boolean>()
  @Input() etatCommande: EtatCommande

  constructor() { }

  ngOnInit(): void {
  }

  CloseAction() {
    this.closed.emit(true)
  }
}
