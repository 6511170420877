import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FilterDto } from 'src/app/filter.dto';
import { partner } from '../partner';
import { PartnerService } from '../partner.service';

@Component({
  selector: 'app-partner-detail',
  templateUrl: './partner-detail.component.html',
  styleUrls: ['./partner-detail.component.css']
})
export class PartnerDetailComponent implements OnInit {
  @Input() partnerId: any
  @Output() closed = new EventEmitter<boolean>()
  partner: partner = new partner()

  constructor(private partnerService: PartnerService) { }

  ngOnInit(): void {
    this.getPartnerInfo();
  }


  getPartnerInfo() {
    let filter = new FilterDto()
    filter.where = { "id": this.partnerId }
    filter.relations = ["abonnementId"]
    this.partnerService.getAllPartners(filter).subscribe(
      data => {
        this.partner = data[0][0]
      }
    )
  }
  CloseAction() {
    this.closed.emit(true)
  }

}
