import { getValueFromToken } from 'src/app/user/user.service';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { FilterDto } from 'src/app/filter.dto';
import { admin, User } from '../user';
import { UserService } from '../user.service';
import { Login } from './login';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {
  user: Login = new Login();
  show: boolean;
  rememberMe: boolean;
  picture: string;
  userId: string;
  name: string;
  isLogin: boolean
  resetMail: string = ''
  userObject: User = new User()
  link: string;
  mailInvalid: boolean;
  mailForm: { from: any; to: string; subject: string; text: any; };
  linkSent: boolean
  nbr_admin: number;
  adminObject: admin = new admin();

  constructor(private serviceUser: UserService, private router: Router) { }
  ngOnInit() {
    this.saveCompte();
    this.userId = getValueFromToken("id")
    // this.name = getValueFromToken("nameUser")
  }

  login() {
    // if (!this.getCookie("loginUser")) {
    //   document.cookie = "loginUser=" + (this.user.email);
    // }
    this.serviceUser.checkAdmin().subscribe(
      data => {
        if (data == 0) {
          this.adminObject = {
            name: "admin",
            email: "admin@admin.com",
            password: "admin123@inveep",
            active: true
          }
          this.serviceUser.createAdmins(this.adminObject).subscribe(
            data => {
              let admin_login = {
                email: "admin@admin.com",
                password: "admin123@inveep",
                rememberMe: false,
              }
              this.AdminLogin(admin_login);
            }
          )

        } else {
          this.AdminLogin(this.user);
        }
      }
    )

  }
  AdminLogin(admin: Login) {
    this.serviceUser.login(admin).subscribe(
      token => {
        var now = new Date();
        var time = now.getTime();
        if (this.rememberMe == true) {
          var expireTime = time + 24000 * 3600;
        } else {
          var expireTime = time + 2000 * 3600;
        }
        now.setTime(expireTime);
        document.cookie = "token="+Object.values(token)+"; expires="+now+";path=/"
        // document.cookie = "iocId=" + Object.values(token)[0] + "; expires=" + now + ";path=/";
        // document.cookie = "role=" + Object.values(token)[2] + "; expires=" + now + ";path=/";
        // document.cookie = "lastLogin=" + Object.values(token)[3] + "; expires=" + now + ";path=/";
        this.router.navigateByUrl('/dashboard')
      },
      err => {
        this.show = true
      }
    )
  }
  saveCompte() {
    // this.picture = this.getCookie('pictureUser')
  }
  onChange($event) {
    if ($event.target.checked == true) {
      this.rememberMe = true
    }
    else {
      this.rememberMe = false
    }
  }
  editEmail() {
    this.userId = null
    document.cookie = "iocId=; expires= Thu, 21 Aug 2014 20:00:00 UTC;path=/";
    document.cookie = "pictureUser=; expires= Thu, 21 Aug 2014 20:00:00 UTC;path=/user";
    document.cookie = "nameUser=; expires= Thu, 21 Aug 2014 20:00:00 UTC;path=/user";
    document.cookie = "loginUser=; expires= Thu, 21 Aug 2014 20:00:00 UTC;path=/user";
  }
  valuechange(value) {
    this.mailInvalid = false
  }
  submit() {
    let filter: FilterDto = new FilterDto()
    filter.relations = ["contactId"]
    filter.where = { email: this.resetMail }
    this.serviceUser.getAllUsers(filter).subscribe(
      user => {
        if (user[0][0]) {
          this.userObject = user[0][0]
          this.link = user[0][0].resToken
          this.sendResetMail()
        } else {
          this.mailInvalid = true
        }
      },
      err => {
      }
    )
  }
  sendResetMail() {
    let resetContent = 'Hello GELIVRO, Définir un nouveau mot de passe ici : http://localhost:4200/user/reset-password?token=' + this.link + 'id' + this.userObject.id + ''

    this.mailForm = {
      "from": environment.emailSociete,
      "to": this.resetMail,
      "subject": "Réinitialisation le mot de passe Gelivro",
      "text": resetContent
    }
  }
}
