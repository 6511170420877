<clr-checkbox-container *ngIf="filterClicked">
  <clr-checkbox-wrapper *ngFor="let item of operationTypeFilter">
    <input type="checkbox" clrCheckbox (change)="onItemChanged(item)" />
    <label>{{ item.name }}</label>
  </clr-checkbox-wrapper>
</clr-checkbox-container>

 <clr-checkbox-container *ngIf="transctionFilter">
    <clr-checkbox-wrapper *ngFor="let item of transactionTypeFilter">
      <input type="checkbox" clrCheckbox (change)="onItemChanged(item)" />
      <label>{{ item.name }}</label>
    </clr-checkbox-wrapper>
  </clr-checkbox-container>

  <clr-checkbox-container *ngIf="filterEtat">
    <clr-checkbox-wrapper *ngFor="let item of items">
      <input type="checkbox" clrCheckbox (change)="onItemChanged(item)" />
      <label>{{ item.name }}</label>
    </clr-checkbox-wrapper>
  </clr-checkbox-container>




