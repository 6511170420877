// import { Transaction } from "../finance/finance";

import { Transaction } from "../user/user";

export class Configuration {
    constructor(
        public id?: number,
        public identity?: string,
        public DBname?: string,
        public name?: string,
        public firstName?: string,
        public lastName?: string,
        public cloudName?: string,
        public apiKey?: string,
        public apiSecret?: string,
        public nom_Domaine?: string,
        public tel?: number,
        public email?: string,
        public mailbox?: string,
        public password?: string,
        public nameComplet?: string,
        public nameCourt?: string,
        public matricule?: string,
        public adresseNumero?: string,
        public adresseRoute?: string,
        public adresseCode?: string,
        public adresseVille?: string,
        public adresseGouvernorat?: string,
        public picture?: string,
        public createdAt?: string,
        public updatedAt?: string,
        public active?: boolean,
        public globaleDiscount?: boolean,
        public promo?: boolean,
        public sendMail?: boolean
    ) { }
}
export class Abonnement {
    constructor(
        public id?: number,
        public dateExp?: string,
        public mensuel?: boolean,
        public nbMensuel?: number,
        public nbInvoice?: number,
        public nbQuotation?: number,
        public nbCaisse?: number,
        public nbUser?: number,
        public annuel?: boolean,
        public premuim?: boolean,
        public facturation_caisse?: boolean,
        public facturation_only?: boolean,
        public livraison?: boolean,
        public transactions?: Transaction[],
        public userId?: number,
        public createdAt?: string,
        public updatedAt?: string,
        public active?: boolean,
    ) { }
}