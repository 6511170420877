

export class EtatCommande {
    constructor(
        public id? : number,
        public name ?: string,
        public coefficient ?: number,
        public description ?: string,
        public createdAt?: Date,
        public updatedAt?: Date,
    ) { }
}