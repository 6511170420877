import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { FilterDto } from '../filter.dto';
import { DeleteDeviseComponent } from './delete-devise/delete-devise.component';
import { Devise, PostDevise } from './devise';

@Injectable({
  providedIn: 'root'
})
export class DeviseServiceService {

  constructor(private http: HttpClient) { }

  getDevises(filter: FilterDto<Devise>): Observable<[Devise[], number]> {
    return this.http.get(environment.api + '/devises?filter=' + JSON.stringify(filter)) as Observable<[Devise[], number]>
  }
  addDevise(Devise: PostDevise): Observable<Devise> {
    return this.http.post<Devise>(environment.api + '/devise', Devise);
  }
  updateDevise(id: number, Devise: PostDevise): Observable<Devise> {
    return this.http.patch<Devise>(environment.api + '/devise/' + JSON.stringify(id), Devise);
  }
  DeleteDevise(id: number): Observable<any> {
    return this.http.delete<Devise>(environment.api + '/devise/' + JSON.stringify(id));
  }
}
