import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { FilterDto } from '../filter.dto';
import { Retenue } from './retenue';

@Injectable({
  providedIn: 'root'
})
export class RetenueService {

  constructor(private http: HttpClient) { }

  getRetenues(filter: FilterDto<Retenue>): Observable<[Retenue[], number]> {
    return this.http.get(environment.api + "/retenue?filter=" + JSON.stringify(filter)) as Observable<[Retenue[], number]>
  }

  addRetenue(model: Retenue): Observable<Retenue> {
    return this.http.post<Retenue>(environment.api + "/retenue", model)
  }
  deleteRetenue(id: number) {
    return this.http.delete(environment.api + "/retenue/" + id)
  }
  getRetenueById(id: number): Observable<Retenue> {
    return this.http.get(environment.api + "/retenue/" + JSON.stringify(id)) as Observable<Retenue>
  }
  updateRetenue(id: number, model: Retenue) {
    return this.http.patch<Retenue>(environment.api + "/retenue/" + id, model)
  }
}

