import { Component } from '@angular/core';
import { ActivatedRoute, ParamMap } from '@angular/router';
import { ClrLoadingState } from '@clr/angular';
import { SettingService } from 'src/app/setting/setting.service';
import { Entreprise, PostEntrepriseDto } from '../../Entreprise';
import { Contact, PictureObject, PostUser, User } from '../../user';
import { UserService } from '../../user.service';

@Component({
  selector: 'app-edit-user',
  templateUrl: './edit-user.component.html',
  styleUrls: ['./edit-user.component.css']
})
export class EditUserComponent {

  pictureArray: PictureObject[] = [];
  model: User = new User();
  fileSize: number;
  alert: {}
  url: string
  formatInvalid: boolean;
  tailleInvalid: boolean
  noImage: boolean;
  userId: number;
  stopLoading: boolean;
  userObject: Entreprise = new Entreprise();
  contact: Contact = new Contact()
  success: boolean;
  imagePicked: boolean;
  canResetPwd: boolean
  isMatchingPwd: boolean = true
  erreurMsg: string
  successMsg: string
  passwordValid: boolean = true
  validateBtnState: ClrLoadingState = ClrLoadingState.DEFAULT;

  constructor(private route: ActivatedRoute, private settingService: SettingService, private userService: UserService) {
  }
  ngOnInit() {
    this.route.paramMap.subscribe((params: ParamMap) => {
      this.userId = +params.get('id')
    })
    this.getEntreprise(this.userId)
  }
  submitAction() {
    this.contact.firstName = this.userObject.name
    this.contact.email = this.userObject.email
    this.userObject.contactId = this.contact
    this.userObject.logo = this.url
    this.settingService.editEntreprise(this.userObject.id, this.userObject).subscribe(
      data => {
        this.success = true
        this.successMsg = 'Utilisateur a été modifier avec succès !'
        this.getEntreprise(this.userId)
      },
      err => {
        console.error('Observer got an error: ' + err)
        this.success = false
        this.erreurMsg = 'Cette adresse mail a été utilisée !'
      },
    )

  }

  changePwd() {
    let user: PostEntrepriseDto = new PostEntrepriseDto();
    user.oldPassword = this.userObject.oldPassword
    this.userService.VerifResetPaswword(this.userId, user).subscribe(
      data => {
        this.passwordValid = data
        if (data) {
          this.isPasswordMatching();
          if (this.isMatchingPwd) {
            this.settingService.editEntreprise(this.userObject.id, this.userObject).subscribe(
              data => {
                this.success = true
                this.successMsg = 'Votre mot de passe est changé avec succès'
              },
              err => {
                console.error('Observer got an error: ' + err)
                this.success = false
              },
            )
          } else {
            this.erreurMsg = 'les mots de passe ne sont pas identiques  '
          }

        } else {
          this.erreurMsg = 'password invalid  '
        }
      }
    )
  }


  getEntreprise(id: number) {
    this.settingService.getEntrepriseInfo(this.userId).subscribe(
      data => {
        this.userObject = data
        this.contact.firstName = this.userObject.name
        this.contact.email = this.userObject.email
        this.contact.tel = this.userObject.contactId?.tel
        this.contact.id = this.userObject.contactId?.id
        this.stopLoading = true
      },
      err => {
        console.error('Observer got an error: ' + err)
      },
    )
  }

  isPasswordMatching() {
    if (this.canResetPwd && this.userObject.password != undefined && this.userObject.repassword != undefined
      && this.userObject.password == this.userObject.repassword && this.userObject.password.length == this.userObject.repassword.length) {
      this.isMatchingPwd = true
    } else {
      this.isMatchingPwd = false
      this.erreurMsg = 'Mots de passe ne sont pas identiques'
    }
  }

  annulerReset() {
    this.userObject.oldPassword = undefined
    this.canResetPwd = false
  }

  picked(event) {
    this.imagePicked = true
    this.tailleInvalid = false
    let fileList: FileList = event.target.files;
    if (fileList.length > 0) {
      const file: File = fileList[0];
      if (this.fileSize > 0.5) {
        this.tailleInvalid = true;
        return
      }
      this.handleInputChange(file);
    }
    else {
      this.noImage = true
    }
  }

  handleInputChange(files) {
    this.formatInvalid = false
    const file = files;
    const pattern = /image-*/;
    const reader = new FileReader();
    if (!file.type.match(pattern)) {
      this.formatInvalid = true
      this.erreurMsg = 'Format de fichier invalide !'
      return;
    }
    reader.onloadend = this._handleReaderLoaded.bind(this);
    reader.readAsDataURL(file);
  }

  async _handleReaderLoaded(e) {
    const picture: PictureObject = new PictureObject()
    picture.base64Data = e.target.result;
    picture.url = e.target.result;
    this.pictureArray[0] = picture
    this.url = picture.url
    this.userObject.logo = this.pictureArray[0].base64Data
  }
  openResetPwd() {
    this.canResetPwd = true
  }
}
