import { Component, OnInit } from '@angular/core';
import { News } from './news';
import { NewsService } from './news.service';
import { FilterDto } from '../filter.dto';

@Component({
  selector: 'app-news',
  templateUrl: './news.component.html',
  styleUrls: ['./news.component.css']
})
export class NewsComponent implements OnInit {

  news: News[] = []
  showDelete: boolean = false
  newsId: number
  newsObject: News
  showDetail: boolean = false

  constructor(private newsService: NewsService) { }

  ngOnInit(): void {
    this.getNews()
  }

  detail(item: News) {
    this.newsObject = item
    this.showDetail = true
  }

  getNews() {
    let filter = new FilterDto()
    filter.relations = ["pictures"]
    filter.where = { "active": true }
    this.newsService.getNews(filter).subscribe(
      data => {
        this.news = data[0]
      }
    )
  }
  
  delete(item: News) {
    this.showDelete = true
    this.newsId = item.id
  }

  refresh() {
    this.getNews()
    this.showDelete = false
  }

}
