import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { FilterDto } from '../filter.dto';
import { partner } from './partner';

@Injectable({
  providedIn: 'root'
})
export class PartnerService {

  constructor(private http: HttpClient) { }

  getAllPartners(filter: FilterDto): Observable<[partner[], number]> {
    return this.http.get(environment.api + "/partners?filter=" + JSON.stringify(filter)) as Observable<[partner[], number]>
  }
  getPartnerById(id: number) {
    return this.http.get(environment.api + "/partners/" + JSON.stringify(id))
  }
  addPartner(model: partner): Observable<partner> {
    return this.http.post<partner>(environment.api + "/partners", model);
  }
  updatePartner(id: number, model: partner): Observable<partner> {
    return this.http.patch<partner>(environment.api + "/partner/" + JSON.stringify(id), model)
  }
  sendMail(mail) {
    return this.http.post(environment.api + '/send', mail)
  }
  deletePartner(id: number) {
    return this.http.delete(environment.api + "/partner/" + JSON.stringify(id));
  }

}
