import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { FilterDto } from 'src/app/filter.dto';
import { Devise } from '../devise';
import { DeviseServiceService } from '../devise-service.service';

@Component({
  selector: 'app-detail-devise',
  templateUrl: './detail-devise.component.html',
  styleUrls: ['./detail-devise.component.css']
})
export class DetailDeviseComponent implements OnInit {

  constructor(
    private deviseService: DeviseServiceService,
    private ActivatedRoute: ActivatedRoute
  ) { }
  id_devise: string
  devise: Devise = new Devise();
  showAlert: boolean
  ngOnInit(): void {
    this.ActivatedRoute.paramMap.subscribe(
      data => {
        this.id_devise = data.get('id');
      }
    )
    this.getDevise(this.id_devise)
  }
  getDevise(id: string) {
    let filter = new FilterDto<Devise>();
    filter.where = { "id": this.id_devise }
    this.deviseService.getDevises(filter).subscribe(
      data => {
        this.devise = data[0][0];
      }
    )
  }
  deleteAction() {
    this.showAlert = true;
  }

  closeAction() {
    this.showAlert = false;
  }


}
