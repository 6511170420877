
export class News {
    constructor(
        public id?: number,
        public content?: string,
        public name?: string,
        public active?: boolean,
        public createdAt?: Date,
        public updatedAt?: Date,
        public pictures?: NewsPicture[]
    ) { }
}

export class NewsPicture {
    constructor(
        public id?: number,
        public url?: string,
        public defaults?: boolean,
        public createdAt?: string,
        public updatedAt?: string,
        public createdBy?: number,
        public updatedBy?: number,
        public active?: boolean,
        public newsId?: number & News
    ) { }
}
