import { Component, HostListener, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ConfigService } from './shared/config/config.service';
import { tokenGetter } from './user/user.service';
import '@clr/icons/shapes/core-shapes';
import '@clr/icons/shapes/essential-shapes';
import '@clr/icons/shapes/technology-shapes';
import '@clr/icons';
import '@clr/icons/shapes/all-shapes';
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit {
  showMenu = false;
  userRole: string;
  clrVerticalNavCollapsed: boolean;

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    event.target.innerWidth;
    if (event.target.innerWidth < 992) {
      this.clrVerticalNavCollapsed = true
    }
    else {
      this.clrVerticalNavCollapsed = false
    }
  }

  constructor(private router: Router, private configService: ConfigService) {

    if (tokenGetter().length == 0) {
      this.router.navigate(['/user/login']);

    }
    this.router.events.subscribe((event: any) => {
      if (event.url) {
        if (event.url === '/user/login' || event.url.indexOf('/user/reset-password?') != -1 || event.url.indexOf('/sales/quotation/print') != -1 || event.url.indexOf('/sales/order/print') != -1 || event.url.indexOf('/transport/print') != -1 || event.url.indexOf('/finance/transaction/print') != -1
          || event.url.indexOf('/finance/crates/print') != -1 || event.url.indexOf('/finance/cheque/print') != -1 || event.url.indexOf('/sales/invoice/print') != -1
          || event.url.indexOf('/user/sign-in') != -1) {
          this.showMenu = false;
        } else {
          this.showMenu = true;
        }
      }
    });
  }

  ngOnInit() {
    this.userRole = this.configService.getRole()
    this.clrVerticalNavCollapsed = false
    if (window.innerWidth < 992) {
      this.clrVerticalNavCollapsed = true
    } else {
      this.clrVerticalNavCollapsed = false
    }
  }
}