import { Component, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { FilterDto } from 'src/app/filter.dto';
import { Timbre } from '../timbre';
import { TimbreService } from '../timbre-service.service';

@Component({
  selector: 'app-timbre-add',
  templateUrl: './timbre-add.component.html',
  styleUrls: ['./timbre-add.component.css']
})
export class TimbreAddComponent implements OnInit {

  constructor(private timberService: TimbreService) { }
  showAlert: boolean;
  addNew: boolean
  alertError: boolean;
  success: boolean;
  timbers: Timbre[];
  ngOnInit(): void {
    let filter = new FilterDto()
    filter.where = { "active": true }
    this.timberService.getTimbres(filter).subscribe(
      data => {
        this.timbers = data[0];
      }
    )
  }
  submit(timberForm: Timbre) {
    const exist = this.timbers.filter(x => x.name == timberForm.name)
    if (exist.length > 0) {
      this.alertError = true;
    } else {
      this.timberService.addTimbre(timberForm).subscribe(
        data => {
          this.success = true;
          this.addNew = true;
        }
      )
    }
  }
  resetForm(form: NgForm) {
    form.reset();
    this.showAlert = false;
    this.addNew = false
    this.ngOnInit();
  }


}
