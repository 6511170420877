<section>
  <h3>Détails d'utilisateur</h3>
  <br>
  <div class="clr-row">
    <div class="clr-col-3">
      <span><b>Id</b></span><br>
      <span><b>Nom</b></span><br>
      <span><b>E-mail</b></span><br>
      <span><b>Telephone</b></span><br>
      <span><b>Adresse</b></span><br>
      <span><b>Autorisation</b></span><br>
      <span><b>Inscription à </b></span><br>
      <!-- <span *ngIf="user.updatedBy"><b>Modifier par </b></span><br> -->
    </div>
    <div class="clr-col">
      <span>{{user.id}}</span><br>
      <span>{{user.name}}</span><br>
      <span>{{user.email}}</span><br>
      <span *ngIf="user.tel">{{user.tel}}</span>
      <span *ngIf="!user.tel"><span class="label label-danger">Non disponible </span></span><br>
      <span *ngIf="address.length>0">{{address}}</span>
      <span *ngIf="address.length==0"><span class="label label-danger">Non disponible</span></span><br>
      <span *ngIf="user.role=='user'"><span class="label label-info">Utilisateur</span></span>
      <span *ngIf="user.role=='writer'"><span class="label label-warning">Traiteur</span></span>
      <span *ngIf="user.role=='admin'"><span class="label label-success">Administrateur</span></span><br>
      <span *ngIf="user.createdAt">
        <strong>{{user.createdAt | date : 'dd/MM/yyyy HH:mm:ss'}}</strong>
      </span>
      <!-- <span *ngIf="user.createdBy && userAdd">
        <strong>{{userAdd.name}}</strong> à <strong>{{user.createdAt | date:
          'dd/MM/yyyy HH:mm:ss'}}</strong>
      </span> -->
      <br>
      <!-- <span *ngIf="user.updatedBy && userEdit">
        <strong>{{userEdit.name}}</strong> à <strong>{{user.updatedAt |
          date: 'dd/MM/yyyy HH:mm:ss' }}</strong>
      </span> -->
    </div>
  </div>
  <br>

  <div class="btn-group btn-sm btn-outline-primary ">
    <button class="btn" routerLink="../../">
      <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" fill="currentColor"
        class="bi bi-arrow-counterclockwise" viewBox="-1 -2 16 16">
        <path fill-rule="evenodd" d="M8 3a5 5 0 1 1-4.546 2.914.5.5 0 0 0-.908-.417A6 6 0 1 0 8 2v1z" />
        <path d="M8 4.466V.534a.25.25 0 0 0-.41-.192L5.23 2.308a.25.25 0 0 0 0 .384l2.36 1.966A.25.25 0 0 0 8 4.466z" />
      </svg> Retour
    </button>
    <button class="btn" routerLink="/user/users/edit-user/{{user.id}}" *ngIf="user.id!=currentUser">
      <clr-icon shape="pencil"></clr-icon> Modifier
    </button>
    <button class="btn" (click)="deleteAction()" *ngIf="user.id!=currentUser">
      <clr-icon shape="trash"></clr-icon> Supprimer
    </button>
  </div>
</section>
<app-delete-user *ngIf="showAlert" (closed)="closeAction()" [userObject]="user"></app-delete-user>