import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { tokenGetter, UserService } from 'src/app/user/user.service';
import jwt_decode from "jwt-decode";
import { FilterDto } from 'src/app/filter.dto';
import { User } from 'src/app/user/user';
import { Abonnement } from 'src/app/setting/setting';
import { Entreprise } from 'src/app/user/Entreprise';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.css']
})
export class DashboardComponent {
  userRole: string;
  filter1: FilterDto<User> = new FilterDto()
  filter2: FilterDto<Abonnement> = new FilterDto()
  countUser: number;
  countAbonnement; number;

  constructor(private router: Router, private userService: UserService) {
    this.userRole = jwt_decode(tokenGetter())["role"];
  }
  ngOnInit() {
    this.getEntreprises();
    //this.getQuotation();
    this.getAbonnement();
  }
  navigateTo(route: string) {
    this.router.navigateByUrl('/' + route);
  }
  getQuotation() {
    this.userService.getUser(this.filter1).subscribe(
      data => {
        this.countUser = data[1]
      })
  }
  getEntreprises() {
    let filter = new FilterDto<Entreprise>()
    this.userService.getEntreprise(filter).subscribe(
      data => {
        this.countUser = data[1];
      }
    )
  }
  getAbonnement() {
    this.userService.getAbonnement(this.filter2).subscribe(
      data => {
        this.countAbonnement = data[1]
      })
  }
}