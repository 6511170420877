import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FilterDto } from 'src/app/filter.dto';
import { User } from 'src/app/user/user';
import { Tax } from '../tax';
import { TaxService } from '../tax.service';

@Component({
  selector: 'app-tax-detail',
  templateUrl: './tax-detail.component.html',
  styleUrls: ['./tax-detail.component.css']
})
export class TaxDetailComponent implements OnInit {

  @Input() tax: Tax;
  @Output() closed = new EventEmitter<boolean>();
  showAlert: boolean;
  searchUser: FilterDto<User> = new FilterDto<User>()
  user: User = new User();

  constructor(private taxService: TaxService) { }

  ngOnInit() {
    this.taxService.getTaxById(this.tax.id).subscribe(
      data => {
        this.tax = data
      })
  }

  close() {
    this.closed.emit(true);
  }
}
