import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { FilterDto } from 'src/app/filter.dto';
import { Configuration } from 'src/app/setting/setting';
import { Entreprise } from 'src/app/user/Entreprise';
import { User } from 'src/app/user/user';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ConfigService {

  constructor(private router: Router, private http: HttpClient) { }

  getCookie(cookieName: string) {
    var cookieValue: string
    var ca = document.cookie.split(';');
    if (ca.length > 0) {
      ca.forEach(element => {
        if (element.includes(cookieName + "=")) {
          cookieValue = element.replace(cookieName + "=", "")
          return cookieValue
        }
      });
    }
    return cookieValue
  }

  getToken() {
    return this.getCookie('token')
  }

  getRole() {
    return this.getCookie('role')
  }

  getProfile() {
    var id = +this.getCookie('iocId')
    var filter = { where: { "id": id }, select: ["picture", "name", "lastName", "email"] }
    return this.http.get(this.getApiUrl() + '/profileUser?filter=' + JSON.stringify(filter))
  }

  getUrl() {
    return this.router.url;
  }

  getApiUrl() {
    return 'http://127.0.0.1:3000'
  }

  getCurrentUser() {
    var id = +this.getCookie('iocId')
    return this.http.get(this.getApiUrl() + '/user/' + JSON.stringify(id))
  }

  addConfiguration(config: Configuration): Observable<Configuration> {
    return this.http.post<Configuration>(environment.api + '/configuration', config);
  }
  getEntreprise(filter: FilterDto<Entreprise>): Observable<[Entreprise[], number]> {
    return this.http.get(environment.api + '/entreprises?filter=' + JSON.stringify(filter)) as Observable<[Entreprise[], number]>
  }
  getUsers(filter: FilterDto<User>): Observable<[User[], number]> {
    return this.http.get(environment.api + '/users?filter=' + JSON.stringify(filter)) as Observable<[User[], number]>
  }

}



