import { Component, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { Router } from '@angular/router';
import { PictureAccount, PictureObject } from 'src/app/user/user';
import { partner } from '../partner';
import { PartnerService } from '../partner.service';
import { UserService } from 'src/app/user/user.service';
import { FilterDto } from 'src/app/filter.dto';
import { environment } from 'src/environments/environment';
import { Abonnement } from 'src/app/setting/setting';
import { SettingService } from 'src/app/setting/setting.service';

@Component({
  selector: 'app-partner-add',
  templateUrl: './partner-add.component.html',
  styleUrls: ['./partner-add.component.css']
})
export class PartnerAddComponent implements OnInit {

  url: string
  tailleInvalid: boolean = false
  erreurMsg: string
  noImage: boolean = false
  formatInvalid: boolean = false
  pictureArray: PictureObject[] = [];
  addNew: boolean = false
  usedMail: boolean = false
  success: boolean = false
  partner: partner = new partner()
  repassword: string
  mail: string[] = []
  abonnement: Abonnement = new Abonnement();
  showConfigAbonnement: boolean = false
  mailSend: { from: any; to: string; subject: string; html: string };



  constructor(private partnerService: PartnerService, private router: Router,
    private userService: UserService, private settingService: SettingService
  ) { }

  ngOnInit(): void {
    this.mail = []
    this.collectAllMails();
  }

  collectAllMails() {
    let filter = new FilterDto()
    filter.select = ["email"]
    this.userService.getEntreprise(filter).subscribe(
      data => {
        data[0].forEach(el => {
          this.mail.push(el.email)
        })
        this.userService.getAllUsers(filter).subscribe(
          res => {
            res[0].forEach(el => {
              this.mail.push(el.email)
            })
          }
        )
        this.partnerService.getAllPartners(filter).subscribe(
          data => {
            data[0].forEach(el => {
              this.mail.push(el.email)
            })
          }
        )
      }
    )
  }

  editAbonnement(event: any) {
    this.abonnement = event
    this.showConfigAbonnement = false
  }

  checkExistanceMail() {
    let index = this.mail.filter(x => x == this.partner.email)
    if (index.length > 0) {
      this.usedMail = true
      this.erreurMsg = "mail est déja utilisé "
    } else {
      this.usedMail = false
    }
  }

  submit() {
    this.partnerService.addPartner(this.partner).subscribe(
      data => {
        this.abonnement.transactions[0].partnerId = data.id
        this.abonnement.transactions[0].real_id = 1
        this.settingService.addAbonnement(this.abonnement).subscribe(
          data => {
            this.partner.abonnementId = data.id
            this.partnerService.updatePartner(data.id, this.partner).subscribe(
              data => {
              }
            )
          }
        )
        this.success = true
        let content = `
        <!DOCTYPE html>
<html lang="en">

<head>
    <meta charset="UTF-8" />
    <meta name="viewport" content="width=device-width, initial-scale=1.0" />
    <title>Partenaire Inveep</title>
    <style>
        body {
            margin: 0;
            padding: 0;
            font-family: Arial, sans-serif;
            font-size: 16px;
            color: #333;
        }

        .container {
            max-width: 600px;
            margin: 0 auto;
            padding: 20px;
            box-sizing: border-box;
            background-color: #f7f7f7;
        }

        .header {
            background-color: #ffffff;
            padding: 20px;
            text-align: center;
            margin-bottom: 20px;
        }

        .header h1 {
            margin: 0;
            font-size: 24px;
            color: #333;
        }

        .message {
            padding: 20px;
            background-color: #ffffff;
            margin-bottom: 20px;
        }

        .message h2 {
            margin: 0;
            font-size: 20px;
            color: #333;
            margin-bottom: 10px;
        }

        .message p {
            margin: 0;
            line-height: 1.5;
        }

        .access {
            padding: 20px;
            background-color: #ffffff;
        }

        .access h2 {
            margin: 0;
            font-size: 20px;
            color: #333;
            margin-bottom: 10px;
        }

        .access p {
            margin: 0;
            line-height: 1.5;
        }

        .button {
            display: inline-block;
            padding: 10px 20px;
            background-color: #4caf50;
            color: #ffffff;
            text-decoration: none;
            border-radius: 4px;
            margin-top: 20px;
        }

        .button:hover {
            background-color: #3e8e41;
        }
    </style>
</head>

<body>
    <div class="container">
        <div class="header">
            <h1>Message de félicitation</h1>
        </div>
        <div class="message">
            <h2>Félicitations, ${this.partner.name}!</h2>
            <p>Vous vous êtes inscrit avec succès sur notre plateforme. Nous sommes ravis de vous compter parmi nous.
            </p>
            <p>Votre compte a été créé et vous pouvez maintenant y accéder en utilisant les informations suivantes :</p>
        </div>
        <div class="access">
            <h2>Données d'accès :</h2>
            <p>Email : ${this.partner.email}</p>
            <p>Mot de passe : ${this.partner.password}</p>
            <p> Code Invitation :${data.codeInvitation} </p>
            <p>Veuillez conserver ces informations en toute sécurité et ne les partagez avec personne. Vous pouvez
                modifier votre mot de passe à tout moment en vous connectant à votre compte.</p>
            <a href="${environment.apiFrontAdmin}" class="button">Connecte-toi maintenant</a>
        </div>
    </div>
</body>

</html>
        `
        this.mailSend = {
          from: environment.emailSociete,
          to: this.partner.email,
          subject: "Partenaire Inveep",
          html: content
        }
        this.partnerService.sendMail(this.mailSend).subscribe()
        setTimeout(() => {
          this.router.navigate(["/partner/partners"])
        }, 2000);
      }
    )
  }

  picked(event) {
    this.tailleInvalid = false
    let fileList: FileList = event.target.files;
    if (fileList.length > 0) {
      const file: File = fileList[0];
      if (file.size > 50000) {
        this.tailleInvalid = true;
        this.erreurMsg = 'La taille de ce fichier est invalide !'
        return
      }
      this.handleInputChange(file);
    }
    else {
      this.noImage = true
      this.erreurMsg = 'Aucune image sélectionnée !'

    }
    let pictureAccount: PictureAccount = new PictureAccount()
  }

  handleInputChange(files) {
    this.formatInvalid = false
    const file = files;
    const pattern = /image-*/;
    const reader = new FileReader();
    if (!file.type.match(pattern)) {
      this.formatInvalid = true
      this.erreurMsg = 'Format de fichier invalide !'
      return;
    }
    reader.onloadend = this._handleReaderLoaded.bind(this);
    reader.readAsDataURL(file);
  }

  async _handleReaderLoaded(e) {
    const picture: PictureObject = new PictureObject()
    picture.base64Data = e.target.result;
    picture.url = e.target.result;
    this.pictureArray[0] = picture
    this.url = picture.url
  }

}
