import { Component, OnInit } from '@angular/core';
import { FilterDto } from 'src/app/filter.dto';
import { Retenue } from '../retenue';
import { RetenueService } from '../retenue.service';

@Component({
  selector: 'app-retenue-add',
  templateUrl: './retenue-add.component.html',
  styleUrls: ['./retenue-add.component.css']
})
export class RetenueAddComponent implements OnInit {

  success: boolean = false
  alertError: boolean = false
  addNew: boolean = false
  retenues: Retenue[] = []

  constructor(private retenueService: RetenueService) { }

  ngOnInit(): void {
    this.getRetenues();
  }

  getRetenues() {
    let filter = new FilterDto()
    filter.where = { "active": true }
    this.retenueService.getRetenues(filter).subscribe(
      data => {
        this.retenues = data[0]
      }
    )
  }
  submit(retenueForm: Retenue) {
    let exist = this.retenues.filter(x => x.name == retenueForm.name && x.value == retenueForm.value)
    if (exist.length == 0) {
      this.retenueService.addRetenue(retenueForm).subscribe(
        data => {
          this.addNew = true
          this.success = true
        }
      )
    } else {
      this.alertError = true
    }
  }

}
