import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, ParamMap } from '@angular/router';
import { User } from '../../user';
import { SettingService } from '../../../setting/setting.service';
import { ConfigService } from 'src/app/shared/config/config.service';
import { Entreprise } from '../../Entreprise';
import { FilterDto } from 'src/app/filter.dto';

@Component({
  selector: 'app-detail-user',
  templateUrl: './detail-user.component.html',
  styleUrls: ['./detail-user.component.css']
})
export class DetailUserComponent implements OnInit {

  user = new Entreprise()
  showAlert: boolean;
  userId: number;
  currentUser: number
  disable: boolean;
  userAdd = new User()
  userEdit = new User()
  address: string = ''
  constructor(private route: ActivatedRoute, private settingService: SettingService, private configService: ConfigService) { }

  ngOnInit(): void {

    this.currentUser = +this.configService.getCookie('iocId')
    this.route.paramMap.subscribe((params: ParamMap) => {
      this.userId = +params.get('id')
    })
    let filter = new FilterDto();
    filter.where = { "id": this.userId }
    filter.relations = ["adressId", "contactId"]
    this.settingService.getEntrepriseInfo(this.userId).subscribe(
      data => {
        this.user = data;
        if (this.user.adressId) {
          this.address = this.user.adressId.state
        }
      },
      err => {
        console.error('Observer got an error: ' + err)
      }
    );

  }

  deleteAction() {
    this.showAlert = true;
  }
  closeAction() {
    this.showAlert = false;
  }



}
