import { Component, Output, EventEmitter, LOCALE_ID, Input } from '@angular/core';
import { ClrDatagridFilterInterface } from '@clr/angular';
import { Subject } from 'rxjs';
import { DateFilter } from './date-filter';

@Component({
  selector: 'app-date-filter',
  templateUrl: './date-filter.component.html',
  styleUrls: ['./date-filter.component.css'],
  providers: [
    { provide: LOCALE_ID, useValue: 'fr' }
  ]
})
export class DateFilterComponent implements ClrDatagridFilterInterface<any> {

  constructor(){

  }

  @Output() private filterDate = new EventEmitter<any>();
  @Input() property:string
  public changes = new Subject<any>();
  dateData: DateFilter = new DateFilter();
  startDate: any;
  endDate: any;
  startDateChanged(date: Date) {
    if(!this.property){this.property="createdAt"}
    if (date instanceof Date) {
      this.dateData.startDate = date;
      this.filterDate.emit(this.dateData);
      this.changes.next(true);
    }
  }

  endDateChanged(date: Date) {
    if (date instanceof Date) {
      this.dateData.endDate = date;
      this.filterDate.emit(this.dateData);
      this.changes.next(true);
    }
  }

  resetStartDate() {
    this.startDate = undefined;
    this.dateData.startDate = undefined;
    this.filterDate.emit(this.dateData);
    this.changes.next(true);
  }

  resetEndDate() {
    this.endDate = undefined;
    this.dateData.endDate = undefined;
    this.filterDate.emit(this.dateData);
    this.changes.next(true);
  }


  accepts(item): boolean {
    return true;
  }

  isActive(): boolean {
    return (this.dateData.startDate && this.dateData.startDate != null) || (this.dateData.endDate && this.dateData.endDate != null);
  }
}
