import { Component, OnInit } from '@angular/core';
import { PictureAccount, PictureObject, Contact, PostUser } from '../../user';
import { SettingService } from '../../../setting/setting.service';
// import { QuotationService } from '../../../sales/quotation/quotation.service';
import { ClrLoadingState } from '@clr/angular';
import { NgForm } from '@angular/forms';
import { ConfigService } from 'src/app/shared/config/config.service';
import { PostEntrepriseDto } from '../../Entreprise';


@Component({
  selector: 'app-add-user',
  templateUrl: './add-user.component.html',
  styleUrls: ['./add-user.component.css']
})
export class AddUserComponent implements OnInit {
  pictureArray: PictureObject[] = [];
  showAlert: boolean;
  contact: Contact = new Contact()
  validateBtnState: ClrLoadingState = ClrLoadingState.DEFAULT;
  fileSize: number;
  url: string
  formatInvalid: boolean;
  tailleInvalid: boolean
  noImage: boolean;
  success: boolean;
  usedMail: boolean;
  addNew: boolean;
  userArray = []
  entrepriseArray = []
  noCnx: boolean
  erreurMsg: string
  idAbonnement: number;
  constructor(private settingService: SettingService,
    private configService: ConfigService) {
  }
  ngOnInit() {
    this.configService.getUsers({}).subscribe(
      data => {
        this.userArray = data[0]
      })
    this.configService.getEntreprise({}).subscribe(
      data => {
        this.userArray = data[0];
      }
    )
    this.idAbonnement = +this.configService.getCookie('idAbonnement')
  }

  submit(userForm: PostEntrepriseDto, form: NgForm) {
    this.validateBtnState = ClrLoadingState.LOADING;
    const existsInBd = this.userArray.filter(element => element.email === userForm.email)
    const existInBdEntrp = this.entrepriseArray.filter(el => el.email === userForm.email)
    if (existsInBd.length > 0 || existInBdEntrp.length > 0) {
      this.usedMail = true
      this.erreurMsg = 'Cette adresse mail a été utilisée !'
      this.validateBtnState = ClrLoadingState.DEFAULT;
    } else {
      //this.contact.firstName = userForm.name
      //this.contact.email = userForm.email
      //userForm.contactId = this.contact
      userForm.logo = this.url
      this.settingService.addEntreprise(userForm)
        .subscribe(
          data => {
            this.showAlert = true
            this.addNew = true
            this.validateBtnState = ClrLoadingState.SUCCESS;
          },
          err => {
            console.error('Observer got an error: ' + err)
            this.noCnx = true
            this.erreurMsg = 'Erreur de téléversement d' + 'image , vérifier votre connexion !'
            this.validateBtnState = ClrLoadingState.DEFAULT;
            this.success = false
          }
        )
    }
  }


  picked(event) {
    this.tailleInvalid = false
    let fileList: FileList = event.target.files;
    if (fileList.length > 0) {
      const file: File = fileList[0];
      if (file.size > 50000) {
        this.tailleInvalid = true;
        this.erreurMsg = 'La taille de ce fichier est invalide !'
        return
      }
      this.handleInputChange(file);
    }
    else {
      this.noImage = true
      this.erreurMsg = 'Aucune image sélectionnée !'

    }
    let pictureAccount: PictureAccount = new PictureAccount()
  }

  handleInputChange(files) {
    this.formatInvalid = false
    const file = files;
    const pattern = /image-*/;
    const reader = new FileReader();
    if (!file.type.match(pattern)) {
      this.formatInvalid = true
      this.erreurMsg = 'Format de fichier invalide !'
      return;
    }
    reader.onloadend = this._handleReaderLoaded.bind(this);
    reader.readAsDataURL(file);
  }

  async _handleReaderLoaded(e) {
    const picture: PictureObject = new PictureObject()
    picture.base64Data = e.target.result;
    picture.url = e.target.result;
    this.pictureArray[0] = picture
    this.url = picture.url
  }

  resetForm(form: NgForm) {
    this.addNew = false;
    form.resetForm();
    this.showAlert = false
    this.url = undefined
    this.pictureArray = []
  }
}
