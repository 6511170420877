import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { FilterDto } from 'src/app/filter.dto';
import { NewsService } from '../news.service';
import { News, NewsPicture } from '../news';
import { PictureObject } from 'src/app/user/user';

@Component({
  selector: 'app-news-edit',
  templateUrl: './news-edit.component.html',
  styleUrls: ['./news-edit.component.css']
})
export class NewsEditComponent implements OnInit {

  newsId: number
  newsObject: News = new News()
  pictureObject: NewsPicture = new NewsPicture()
  showError: boolean = false
  erreurMsg: string = ""
  success: boolean = false

  constructor(private activatedRoute: ActivatedRoute, private router: Router,
    private newsService: NewsService) { }

  ngOnInit(): void {
    this.activatedRoute.params.subscribe(
      param => {
        this.newsId = + param["id"]
        this.getNewsInfo(this.newsId)
      }
    )
  }
  deletePicture() {
    this.pictureObject.url = ""
  }
  getNewsInfo(id: number) {
    let filter = new FilterDto()
    filter.where = { "id": id }
    filter.relations = ["pictures"]
    this.newsService.getNews(filter).subscribe(
      data => {
        this.newsObject = data[0][0]
        if (this.newsObject?.pictures?.length > 0) {
          this.pictureObject = this.newsObject?.pictures[0]
        }
      }
    )
  }
  submit() {
    let news = new News()
    news.content = this.newsObject.content
    news.name = this.newsObject.name
    this.newsService.updateNews(this.newsId, news).subscribe(
      data => {
        if (this.pictureObject?.id == null) {
          this.pictureObject.newsId = this.newsId
          this.newsService.addPictures(this.pictureObject).subscribe(
            data => {
              this.success = true
              setTimeout(() => {
                this.router.navigate(["news/news"])
              }, 3000);
            }
          )
        } else {
          this.newsService.updatePictureNews(this.pictureObject?.id, this.pictureObject).subscribe(
            data => {
              this.success = true
              setTimeout(() => {
                this.router.navigate(["news/news"])
              }, 3000);
            }
          )
        }
      }
    )
  }

  picked(event) {
    let fileList: FileList = event.target.files;
    if (fileList.length > 0) {
      const file: File = fileList[0];
      if (file.size > 500000) {
        this.showError = true;
        this.erreurMsg = 'Taille fichier invalide !'
        return;
      }
      this.handleInputChange(file);
    }
    else {
      this.showError = true
      this.erreurMsg = 'Aucune image sélectionnée !'
    }
  }

  handleInputChange(files) {
    this.showError = false
    const file = files;
    const pattern = /image-*/;
    const reader = new FileReader();
    if (!file.type.match(pattern)) {
      this.showError = true
      this.erreurMsg = "Format de fichier est invalide"
      return;
    }
    reader.onloadend = this._handleReaderLoaded.bind(this);
    reader.readAsDataURL(file);
  }

  async _handleReaderLoaded(e) {
    const picture: PictureObject = new PictureObject()
    picture.base64Data = e.target.result;
    picture.url = e.target.result;
    this.pictureObject.url = picture.url
  }
}
