import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { FilterDto } from '../filter.dto';
import { Observable } from 'rxjs';
import { News, NewsPicture } from './news';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class NewsService {

  constructor(private http: HttpClient) { }

  getNews(filter: FilterDto): Observable<[News[], number]> {
    return this.http.get(environment.api + "/news?filter=" + JSON.stringify(filter)) as Observable<[News[], number]>
  }

  addNews(model: News): Observable<News> {
    return this.http.post<News>(environment.api + "/news", model)
  }

  addPictures(model: NewsPicture): Observable<NewsPicture> {
    return this.http.post<NewsPicture>(environment.api + "/picture", model)
  }

  updateNews(id: number, model: News): Observable<News> {
    return this.http.patch<News>(environment.api + "/news/" + JSON.stringify(id), model)
  }

  updatePictureNews(id: number, model: NewsPicture): Observable<NewsPicture> {
    return this.http.patch<NewsPicture>(environment.api + "/picturenews/" + JSON.stringify(id), model)
  }

  deleteNews(id: number) {
    return this.http.delete(environment.api + "/news/" + JSON.stringify(id))
  }
}
