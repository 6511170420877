<div class="modal">
    <div class="modal-dialog" role="dialog" aria-hidden="true">
        <div class="modal-content">
            <div class="modal-header">
                <h3 class="modal-title">
                    Detail partenaire </h3>
                <svg xmlns="http://www.w3.org/2000/svg" style="cursor: pointer;" (click)="CloseAction()" width="20"
                    height="20" fill="currentColor" class="bi bi-x-lg" viewBox="0 0 16 16">
                    <path
                        d="M2.146 2.854a.5.5 0 1 1 .708-.708L8 7.293l5.146-5.147a.5.5 0 0 1 .708.708L8.707 8l5.147 5.146a.5.5 0 0 1-.708.708L8 8.707l-5.146 5.147a.5.5 0 0 1-.708-.708L7.293 8 2.146 2.854Z" />
                </svg>
            </div>
            <div class="modal-body">
                <div class="clr-row">
                    <div class="clr-col-3">
                        <span><b> Id </b></span><br>
                        <span><b> Nom </b></span><br>
                        <span><b> Type </b></span><br>
                        <span><b> Date de création</b></span><br>
                    </div>
                    <div class="clr-col-9">
                        <span>{{partner.id}}</span><br>
                        <span>{{partner?.name}}</span><br>
                        <span *ngIf="partner?.abonnementId?.premuim" class="label label-info">Premuim</span>
                        <span *ngIf="partner?.abonnementId?.facturation_only"
                            class="label label-info">Facturation</span>
                        <span *ngIf="partner?.abonnementId?.facturation_caisse" class="label label-info">Facturation +
                            Caisse</span>
                        <span *ngIf="partner?.abonnementId?.livraison" class="label label-info">Livraison</span>
                        <span *ngIf="!partner?.abonnementId" class="label label-danger"> non disponible </span><br>
                        <span><b>{{partner.createdAt | date:'dd/MM/yyyy HH:mm'}}</b><br>
                        </span>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<div class="modal-backdrop" aria-hidden="true"></div>