import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { environment } from "src/environments/environment";
import { FilterDto } from "../filter.dto";
import { Timbre } from "./timbre";

@Injectable({
    providedIn: 'root'
})
export class TimbreService {
    constructor(private http: HttpClient) { }
    getTimbres(filter: FilterDto): Observable<[Timbre[], number]> {
        return this.http.get(environment.api + "/timbres?filter=" + JSON.stringify(filter)) as Observable<[Timbre[], number]>;
    }
    addTimbre(timberModel: Timbre): Observable<Timbre> {
        return this.http.post<Timbre>(environment.api + "/timbre", timberModel)
    }
    updateTimbre(id: number, model: Timbre): Observable<Timbre> {
        return this.http.patch<Timbre>(environment.api + "/timbre/" + JSON.stringify(id), model)
    }
    DeleteTimbre(id: number) {
        return this.http.delete<Timbre>(environment.api + "/timbre/" + JSON.stringify(id))
    }

}