import { Component } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, ValidatorFn, Validators } from '@angular/forms';
// import { OrderService } from 'src/app/sales/order/order.service';
// import { QuotationService } from 'src/app/sales/quotation/quotation.service';
import { SettingService } from 'src/app/setting/setting.service';
import { environment } from 'src/environments/environment.prod';
import { User } from '../user';

function passwordMatchValidator(password: string): ValidatorFn {
  return (control: FormControl) => {
    if (!control || !control.parent) {
      return null;
    }
    return control.parent.get(password).value === control.value ? null : { mismatch: true };

  };
}
@Component({
  selector: 'app-sign-in',
  templateUrl: './sign-in.component.html',
  styleUrls: ['./sign-in.component.css']
})
export class SignInComponent {
  showAlert: boolean;
  usedMail: boolean;
  userArray = []
  erreurMsg: string
  objectUser: User = new User();
  mail: { from: any; to: string; subject: string; text: any; };
  signForm: FormGroup;

  constructor(private fb: FormBuilder, private settingService: SettingService,
    //  private quotationService: QuotationService, private orderService: OrderService
     ) {
    this.signForm = this.fb.group({
      name: [''],
      email: [''],
      password: ['', [
        Validators.required,
        Validators.minLength(4),
        Validators.maxLength(12)
      ]],
      repassword: ['', [
        Validators.required,
        Validators.minLength(4),
        Validators.maxLength(12),
        passwordMatchValidator('password')

      ]]
    });
  }
  submit() {
    // this.quotationService.getUser({}).subscribe(
    //   data => {
    //     this.userArray = data[0]
    //     const existsInBd = this.userArray.filter(element => element.email === this.objectUser.email)
    //     if (existsInBd.length > 0) {
    //       this.usedMail = true
    //       this.erreurMsg = 'Cette adresse mail a été utilisée !'
    //     }
    //     else {
    //       this.objectUser.role = "admin"
    //       this.settingService.addUsers(this.objectUser).subscribe(
    //         data => {
    //           this.showAlert = true
    //           this.usedMail = false
    //           this.mail = {
    //             "from": environment.emailSociete,
    //             "to": this.objectUser.email,
    //             "subject": "Compte Inveep",
    //             "text": "Salut, Votre compte gratuit INVEEP à été créé avec succès"
    //           }
    //           this.orderService.sendMail(this.mail).subscribe()
    //         },
    //         err => {
    //           console.error('Observer got an error: ' + err)
    //         }
    //       )
    //     }
    //   })
  }
}