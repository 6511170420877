<div class="modal">
    <div class="modal-dialog" role="dialog" aria-hidden="true">
        <div class="modal-content">
            <div class="modal-header">
                <h3 class="modal-title">
                    Detail Demande</h3>
                <svg xmlns="http://www.w3.org/2000/svg" style="cursor: pointer;" (click)="CloseAction()" width="20"
                    height="20" fill="currentColor" class="bi bi-x-lg" viewBox="0 0 16 16">
                    <path
                        d="M2.146 2.854a.5.5 0 1 1 .708-.708L8 7.293l5.146-5.147a.5.5 0 0 1 .708.708L8.707 8l5.147 5.146a.5.5 0 0 1-.708.708L8 8.707l-5.146 5.147a.5.5 0 0 1-.708-.708L7.293 8 2.146 2.854Z" />
                </svg>
            </div>
            <div class="modal-body">
                <div class="clr-row">
                    <div class="clr-col-3">
                        <span><b> Id </b></span><br>
                        <span><b> Nom Utilisateur </b></span><br>
                        <span><b> Message </b></span>
                        <br>
                        <span><b> Date de création</b></span><br>
                    </div>
                    <div class="clr-col-9">
                        <span>{{feedbackObject.id}}</span><br>
                        <span>{{feedbackObject?.entrepriseId?.name}}</span><br>
                        <span>{{feedbackObject?.content}}</span><br>
                        <span>{{feedbackObject?.createdAt | date : 'dd/MM/yyyy à HH:mm'}}</span><br>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<div class="modal-backdrop" aria-hidden="true"></div>