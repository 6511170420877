<div>
  <h4> Choisir le plan d'abonnement </h4>
  <div class="clr-row">
    <div class="clr-col-12">
      <form clrForm>
        <clr-radio-container clrInline>
          <label> Type de contrat </label>
          <clr-radio-wrapper>
            <input type="radio" clrRadio name="options" required value="true" [(ngModel)]="abonnement.mensuel"
              (change)="onCheckPlan($event)" />
            <label>Mensuel</label>
          </clr-radio-wrapper>
          <clr-radio-wrapper>
            <input type="radio" clrRadio name="options" required value="false" [(ngModel)]="abonnement.annuel"
              (change)="onCheckPlan($event)" />
            <label>Annuel</label>
          </clr-radio-wrapper>
        </clr-radio-container>
        <div *ngIf="showMensuel">
          <clr-input-container>
            <label>N° des mois</label>
            <input clrInput type="number" name="nbMensuel" min=1 [(ngModel)]="nbMensuel" />
          </clr-input-container>
        </div>
      </form>
    </div>
  </div>
  <br>
  <div class="clr-row" *ngIf="!addAbonnement && (abonnement.annuel || (abonnement.mensuel && nbMensuel))">
    <div class="clr-row">
      <div class="clr-col-6 ">
        <a (click)="goldAbonnement()">
          <img src="assets/premuim.jpg" width="500" height="400" class="modMobile">
        </a>
      </div>
      <div class="clr-col-6 ">
        <a (click)="FacturationCaisse()">
          <img src="assets/facturation_&_caisse.jpg" width="500" height="400" class="modMobile">
        </a>
      </div>
    </div>
    <div class="clr-row">
      <div class="clr-col-6 ">
        <a (click)="FacturationOnly()">
          <img src="assets/facturation.jpg" width="500" height="400" class="modMobile">
        </a>
      </div>
      <div class="clr-col-6 ">
        <a (click)="Livraison()">
          <img src="assets/livraison.jpg" width="500" height="400" class="modMobile">
        </a>
      </div>
    </div>
  </div>
</div>
<div class="clr-row" *ngIf="addAbonnement">
  <div class="clr-col-6">
    <form clrForm>
      <clr-input-container>
        <label>N° des users</label>
        <input clrInput type="number" min=0 name="nbUser" [(ngModel)]="nbUser" />
      </clr-input-container>
      <div *ngIf="abonnement.premuim || abonnement.facturation_caisse">
        <clr-input-container>
          <label>Nombre des caisses</label>
          <input clrInput type="number" min=0 name="nbCaisse" [(ngModel)]="nbCaisse" />
        </clr-input-container>
      </div>

      <clr-select-container>
        <label>Mode de paiement</label>
        <select clrSelect name="type" [(ngModel)]="selectedValue">
          <option [value]="1">Espèce</option>
          <option [value]="2">Chèque</option>
          <option [value]="3">Virement</option>
        </select>
      </clr-select-container>
      <div *ngIf="selectedValue == 2">
        <clr-input-container>
          <label>Numéro de cheque</label>
          <input clrInput type="number" name="checkNumber" [(ngModel)]="transaction.checkNumber" />
        </clr-input-container>
        <clr-date-container>
          <label>Date d'échéance</label>
          <input type="date" [(clrDate)]="transaction.dueDate">
        </clr-date-container>
        <clr-combobox-container>
          <label>Banque *</label>
          <clr-combobox name="name" [(ngModel)]="transaction.bankId" (clrInputChange)="onSearchBanque($event)">
            <clr-options>
              <clr-option>
              </clr-option>
              <clr-option *ngFor="let item of banks" clrValue={{item.name}} (click)="bankSelect(item)">
                <clr-icon shape="block"></clr-icon>{{item.name}}
              </clr-option>
            </clr-options>
          </clr-combobox>
        </clr-combobox-container>
        <clr-control-helper>
          <button class="btn btn-link btn-sm" id="banqueBtn" (click)="AddBanquePopUp()">
            Ajouter Banque</button>
        </clr-control-helper>
        <clr-input-container>
          <label>Compte bancaire </label>
          <input clrInput name="ban" [(ngModel)]="transaction.ban" />
        </clr-input-container>
        <clr-input-container>
          <label>Image de chèque</label>
        </clr-input-container>
        <cds-form-group layout="vertical">
          <cds-file>
            <label></label>
            <input type="file" (change)="picked($event)" />
          </cds-file>
        </cds-form-group>
      </div>
      <div *ngIf="selectedValue == '3'">
        <clr-input-container>
          <label> Numéro de cheque*</label>
          <input clrInput name="checkNumber" [(ngModel)]="transaction.checkNumber" required />
          <clr-control-error>Ce champ est obligatoire </clr-control-error>
        </clr-input-container>
        <clr-date-container>
          <label> Date d'échéance *</label>
          <input type="date" [(clrDate)]="transaction.dueDate" name="transaction.dueDate" required>
          <clr-control-error>Ce champ est obligatoire </clr-control-error>
        </clr-date-container>
        <clr-select-container>
          <label>Banque *</label>
          <select clrSelect name="selectedBank" [(ngModel)]="transaction.bankId" required>
            <option *ngFor="let item of banks" [ngValue]="item">{{item.name}}
            </option>
          </select>
          <clr-control-error>Ce champ est obligatoire </clr-control-error>
        </clr-select-container>
        <clr-control-helper>
          <button class="btn btn-link btn-sm" id="banqueBtn" (click)="AddBanquePopUp()">
            Ajouter Banque
          </button>
        </clr-control-helper>
        <clr-input-container>
          <label> Compte bancaire *</label>
          <input clrInput name="ban" [(ngModel)]="transaction.ban" required />
          <clr-control-error>Ce champ est obligatoire </clr-control-error>
        </clr-input-container>
        <div class="clr-row">
          <div class="clr-col-md-2">
            <clr-input-container>
              <label> Chèque *</label>
            </clr-input-container>
          </div>
          <div class="clr-col-md-6">
            <div class="img" *ngIf="url">
              <div class="card">
                <div class="card-img container">
                  <img [src]="url">
                </div>
              </div>
            </div>
            <br>
            <cds-form-group layout="vertical">
              <cds-file>
                <label></label>
                <input type="file" (change)="picked($event)" />
                <cds-control-message status="error" *ngIf="!url">Ce champ est obligatoire
                </cds-control-message>
              </cds-file>
            </cds-form-group>
            <br>
          </div>
        </div>
      </div>
      <br>
      <div class="btn-group btn-outline-primary">
        <button class="btn btn-sm" *ngIf="!showAbonnee" (click)="delAbonnement()">
          <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" fill="currentColor"
            class="bi bi-arrow-counterclockwise" viewBox="-1 -2 16 16">
            <path fill-rule="evenodd" d="M8 3a5 5 0 1 1-4.546 2.914.5.5 0 0 0-.908-.417A6 6 0 1 0 8 2v1z" />
            <path
              d="M8 4.466V.534a.25.25 0 0 0-.41-.192L5.23 2.308a.25.25 0 0 0 0 .384l2.36 1.966A.25.25 0 0 0 8 4.466z" />
          </svg> retour
        </button>
        <button class="btn btn-sm" (click)="postAbonnement()">
          <svg id="btn_plus" xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
            class="bi bi-plus" viewBox="-1 -4 16 16">
            <path
              d="M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4z" />
          </svg> ajouter
        </button>
      </div>
    </form>
  </div>
  <div class="clr-col-6">
    <div class="clr-row">
      <div class="clr-col-lg-5 clr-col-md-8 clr-col-12">
        <div class="card">
          <div class="card-block">
            <div class="card-text" *ngIf=" !editAbonnement && !nbUser && !amountCompany">
              Prix HT :{{amount}}
            </div>
            <div class="card-text" *ngIf="!editAbonnement &&( nbUser || amountCompany )">
              Prix HT :
              {{amount + ((nbUser-2)*6 )+ ((nbCaisse - 1 ) *20)}}
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<!-- <div *ngIf="">
  <div class="clr-row">
    <div class="clr-col-3">
      <span><b> {{ 'commun.ab' | translate }}</b></span><br>
      <span><b> {{ 'commun.dtc' | translate }}</b></span><br>
      <span><b> {{ 'commun.type' | translate }}</b></span><br>
      <span *ngIf="abonnementData.nbUser"><b> {{ 'commun.tu' | translate }} </b></span>
    </div>

    <div class="clr-col-9">
      <span *ngIf="abonnementData.premuim"> {{ 'commun.gl' | translate }}</span>
      <span *ngIf="abonnementData.facturation_only">Facturation</span>
      <span *ngIf="abonnementData.facturation_caisse">Facturation + Caisse</span>
      <span *ngIf="abonnementData.livraison">Livraison</span><br>
      <span>{{abonnementData.createdAt | date:'dd/MM/yyyy à HH:mm'}}</span><br>
      <span *ngIf="abonnementData.mensuel"> {{ 'commun.ms' | translate }}<span> {{
          'commun.nmo' | translate }}:
          {{abonnementData.nbMensuel}}</span></span>
      <span *ngIf="abonnementData.annuel"> {{ 'commun.an' | translate }}</span><br>
      <span *ngIf="abonnementData.nbUser"><b>{{abonnementData.nbUser}}</b></span>
    </div>
  </div>
  <br>
  <div class="row">
    <div class="btn-outline-primary">
      <button class="btn btn-sm" (click)="EditAbonnement()"> {{ 'commun.ma' | translate
        }}</button>
    </div>
  </div>
</div> -->