import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { NewsService } from '../news.service';

@Component({
  selector: 'app-news-delete',
  templateUrl: './news-delete.component.html',
  styleUrls: ['./news-delete.component.css']
})
export class NewsDeleteComponent implements OnInit {

  @Input() newsId: any
  @Output() closed = new EventEmitter<boolean>()
  @Output() saved = new EventEmitter<boolean>()
  constructor(private newsService: NewsService) { }

  ngOnInit(): void {
  }

  close() {
    this.closed.emit(true)
  }

  save() {
    this.newsService.deleteNews(this.newsId).subscribe(
      data => {
        this.saved.emit(true)
      }
    )
  }

}
