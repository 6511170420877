import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ClrDatagridStateInterface } from '@clr/angular';
import { FilterDto } from '../filter.dto';
import { FilterService } from '../shared/filter.service';
import { Timbre } from './timbre';
import { TimbreService } from './timbre-service.service';

@Component({
  selector: 'app-timbre',
  templateUrl: './timbre.component.html',
  styleUrls: ['./timbre.component.css']
})
export class TimbreComponent implements OnInit {


  constructor(private timbreService: TimbreService, private filterService: FilterService,
    private router: Router
  ) { }

  timbres: Timbre[] = []
  alertDelete: boolean = false
  timbreObject: Timbre
  filter: FilterDto
  count: number = 0
  ngOnInit(): void {
  }

  getTimbres(filter: FilterDto) {
    this.timbreService.getTimbres(filter).subscribe(
      data => {
        this.timbres = data[0]
        this.count = data[1]
      }
    )
  }
  closeAction() {
    this.alertDelete = false
  }
  deleteAction(item: Timbre) {
    this.timbreObject = item
    this.alertDelete = true
  }
  addTimbre() {
    this.router.navigate(['/timbre/timbres/timbre-add']);
  }
  refresh(state: ClrDatagridStateInterface) {
    this.filter = this.filterService.bind(state);
    this.filter.take = 10
    this.filter.order = { "id": "DESC" }
    this.filter.where = { "active": true }
    this.getTimbres(this.filter);
  }
}
