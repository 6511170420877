import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { ClarityModule } from '@clr/angular';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { CoreModule } from './core/core.module';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { FormsModule } from '@angular/forms';
import { UserModule } from './user/user.module';
import { AuthInterceptor } from './auth/auth.interceptor';
import { AuthGuard } from './auth/auth.guard';
import { DashboardModule } from './dashboard/dashboard.module';
import { CdsModule } from '@cds/angular';
import { DeviseComponent } from './devise/devise.component';
import { AddDeviseComponent } from './devise/add-devise/add-devise.component';
import { EditDeviseComponent } from './devise/edit-devise/edit-devise.component';
import { DeleteDeviseComponent } from './devise/delete-devise/delete-devise.component';
import { DetailDeviseComponent } from './devise/detail-devise/detail-devise.component';
import { TimbreAddComponent } from './timbre/timbre-add/timbre-add.component';
import { TimbreComponent } from './timbre/timbre.component';
import { TimbreEditComponent } from './timbre/timbre-edit/timbre-edit.component';
import { TimbreDetailComponent } from './timbre/timbre-detail/timbre-detail.component';
import { TimbreDeleteComponent } from './timbre/timbre-delete/timbre-delete.component';
import { RetenueComponent } from './retenue/retenue.component';
import { RetenueAddComponent } from './retenue/retenue-add/retenue-add.component';
import { RetenueEditComponent } from './retenue/retenue-edit/retenue-edit.component';
import { RetenueDetailComponent } from './retenue/retenue-detail/retenue-detail.component';
import { RetenueDeleteComponent } from './retenue/retenue-delete/retenue-delete.component';
import { TaxComponent } from './tax/tax.component';
import { TaxAddComponent } from './tax/tax-add/tax-add.component';
import { TaxDeleteComponent } from './tax/tax-delete/tax-delete.component';
import { TaxEditComponent } from './tax/tax-edit/tax-edit.component';
import { TaxDetailComponent } from './tax/tax-detail/tax-detail.component';
import { PartnerComponent } from './partner/partner.component';
import { PartnerAddComponent } from './partner/partner-add/partner-add.component';
import { PartnerEditComponent } from './partner/partner-edit/partner-edit.component';
import { PartnerDetailComponent } from './partner/partner-detail/partner-detail.component';
import { PartnerDeleteComponent } from './partner/partner-delete/partner-delete.component';
import { AbonnementConfigComponent } from './partner/abonnement-config/abonnement-config.component';
import { NgbPaginationModule } from '@ng-bootstrap/ng-bootstrap';
import { NewsComponent } from './news/news.component';
import { NewsAddComponent } from './news/news-add/news-add.component';
import { NewsDetailComponent } from './news/news-detail/news-detail.component';
import { NewsDeleteComponent } from './news/news-delete/news-delete.component';
import { NewsEditComponent } from './news/news-edit/news-edit.component';
import { UserDemandsComponent } from './user-demands/user-demands.component';
import { DemandDetailComponent } from './user-demands/demand-detail/demand-detail.component';
import { AlertDeleteComponent } from './user-demands/alert-delete/alert-delete.component';
import { EtatCommandeComponent } from './etat-commande/etat-commande.component';
import { EtatCommandeAddComponent } from './etat-commande/etat-commande-add/etat-commande-add.component';
import { EtatCommandeEditComponent } from './etat-commande/etat-commande-edit/etat-commande-edit.component';
import { EtatCommandeDetailComponent } from './etat-commande/etat-commande-detail/etat-commande-detail.component';
import { EtatCommandeDeleteComponent } from './etat-commande/etat-commande-delete/etat-commande-delete.component';



@NgModule({
  declarations: [
    AppComponent,
    DeviseComponent,
    AddDeviseComponent,
    EditDeviseComponent,
    DeleteDeviseComponent,
    DetailDeviseComponent,
    TimbreAddComponent,
    TimbreComponent,
    TimbreEditComponent,
    TimbreDetailComponent,
    TimbreDeleteComponent,
    RetenueComponent,
    RetenueAddComponent,
    RetenueEditComponent,
    RetenueDetailComponent,
    RetenueDeleteComponent,
    TaxComponent,
    TaxAddComponent,
    TaxDeleteComponent,
    TaxEditComponent,
    TaxDetailComponent,
    PartnerComponent,
    PartnerAddComponent,
    PartnerEditComponent,
    PartnerDetailComponent,
    PartnerDeleteComponent,
    AbonnementConfigComponent,
    NewsComponent,
    NewsAddComponent,
    NewsDetailComponent,
    NewsEditComponent,
    NewsDeleteComponent,
    UserDemandsComponent,
    DemandDetailComponent,
    AlertDeleteComponent,
    EtatCommandeComponent,
    EtatCommandeAddComponent,
    EtatCommandeEditComponent,
    EtatCommandeDetailComponent,
    EtatCommandeDeleteComponent,
    // ActivitesComponent,

  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    ClarityModule,
    BrowserAnimationsModule,
    HttpClientModule,
    CoreModule,
    FormsModule,
    UserModule,
    DashboardModule,
    CdsModule,
    NgbPaginationModule

  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true,
    },
    AuthGuard,
    // { provide: createPopper, useValue: createPopper }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
