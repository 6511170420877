import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { partner } from '../partner';
import { PartnerService } from '../partner.service';

@Component({
  selector: 'app-partner-delete',
  templateUrl: './partner-delete.component.html',
  styleUrls: ['./partner-delete.component.css']
})
export class PartnerDeleteComponent implements OnInit {
  @Output() closed = new EventEmitter<boolean>()
  @Input() partnerId: number

  constructor(private partnerService: PartnerService) { }

  ngOnInit(): void {
  }

  close() {
    this.closed.emit(true)
  }
  save() {
    this.partnerService.deletePartner(this.partnerId).subscribe(
      data => {
        this.close();
      }
    )
  }

}
